import React from 'react';
import { Form, Label } from 'semantic-ui-react';

export default function FormInput({
  required,
  input,
  type,
  label,
  placeholder,
  as: As = Form.Input,
  meta,
  beforeInput,
  ...props
}) {
  function handleChange(e, { value }) {
    return input.onChange(value);
  }

  return (
    <Form.Field required={required} error={Boolean(meta.error && meta.touched)}>
      {beforeInput}
      {label && <label>{label}</label>}
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {meta.touched &&
        meta.error && (
          <Label color="red" pointing>
            {meta.error}
          </Label>
        )}
    </Form.Field>
  );
}
