export const append = (url, query) => {
  const [path, search] = url.split('?');
  const params = new URLSearchParams(search || '');
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.set(key, query[key]);
    } else {
      params.delete(key);
    }
  });
  return `${path}${Array.from(params.values()).length ? '?' : ''}${params}`;
};
