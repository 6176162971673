import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Modal from 'components/Modal';
import { reaction } from 'mobx';
import RoleForm from './RoleForm';
import { Form } from 'react-final-form';
import { permissionDefinitions, hasPermissions } from 'utils/rbac';

import {
  Container,
  Divider,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Dropdown,
  Button
} from 'semantic-ui-react';

@inject('roles', 'appSession', 'me')
@observer
export default class Roles extends React.Component {
  state = {
    showCreateDialog: false
  };
  constructor(props) {
    super(props);
    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
  }

  componentDidMount() {
    this.props.roles.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  handleRemove = (role) => {
    const { roles } = this.props;
    roles.delete(role);
  };

  handleCreate = (body) => {
    const { roles } = this.props;
    return roles.create(body).then((result) => {
      if (result instanceof Error) return alert(result.message);
      const dialog = this.createDialog.current;
      if (dialog) dialog.close();
    });
  };

  handleUpdate = (body) => {
    const { roles } = this.props;
    return roles.update(body).then((err) => {
      if (err instanceof Error) return alert(`Error: ${err.message}`);
      const dialog = this.editDialog.current;
      if (dialog) dialog.close();
    });
  };

  render() {
    const { roles, organization, me } = this.props;
    const createStatus = roles.getStatus('create');
    const updateStatus = roles.getStatus('update');
    const deleteStatus = roles.getStatus('delete');
    const listStatus = roles.getStatus('list');

    const { editItem } = this.state;
    return (
      <Container>
        <Header as="h2" className="page-title">
          Roles
        </Header>
        <Divider hidden />
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={5}>Permissions</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created At</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!roles.items.length && (
                <Table.Row>
                  <Table.Cell>No roles yet</Table.Cell>
                </Table.Row>
              )}
              {roles.items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell verticalAlign="top">
                      <Header as="h4">
                        <Header.Content>{item.name}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Table definition celled size="small">
                        <Table.Body>
                          {Object.keys(item.permissions).map((model) => {
                            return (
                              <Table.Row>
                                <Table.Cell>
                                  {permissionDefinitions[model]}
                                </Table.Cell>
                                <Table.Cell>
                                  {item.permissions[model]}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                    <Table.Cell verticalAlign="top">
                      {moment(item.createdAt).format('LL')}
                    </Table.Cell>
                    <Table.Cell textAlign="center" verticalAlign="top">
                      {
                        <Button.Group basic>
                          <Modal
                            title={`Are you sure you want to delete "${item.name}"?`}
                            status={deleteStatus}
                            trigger={
                              <Button
                                icon="delete"
                                title="Delete"
                                disabled={
                                  !hasPermissions(me.user, 'roles', 'write')
                                }
                              />
                            }
                            action={{
                              content: 'Delete',
                              onClick: () => this.handleRemove(item)
                            }}
                          >
                            <p />
                          </Modal>
                          <Button
                            icon="edit"
                            title="Edit"
                            disabled={
                              !hasPermissions(me.user, 'roles', 'write')
                            }
                            onClick={() => this.setState({ editItem: item })}
                          />
                        </Button.Group>
                      }
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>

        <Modal
          ref={this.createDialog}
          trigger={
            <Button
              style={{ marginTop: '20px' }}
              primary
              content="Create New Role"
              disabled={!hasPermissions(me.user, 'roles', 'write')}
            />
          }
          title="Create Role"
          action={{
            content: 'Create',
            primary: true,
            loading: createStatus.request,
            onClick: () =>
              document
                .getElementById('role-create')
                .dispatchEvent(new Event('submit'))
          }}
        >
          <Form
            id="role-create"
            onSubmit={this.handleCreate}
            render={RoleForm}
          />
        </Modal>

        {editItem && (
          <Modal
            open
            onClose={() => this.setState({ editItem: false })}
            ref={this.editDialog}
            title={`Edit "${editItem.name}"`}
            action={{
              primary: true,
              loading: updateStatus.request,
              content: 'Save',
              onClick: () =>
                document
                  .getElementById('role-edit')
                  .dispatchEvent(new Event('submit'))
            }}
          >
            <Form
              id="role-edit"
              onSubmit={this.handleUpdate}
              render={RoleForm}
              initialValues={{
                name: editItem.name,
                permissions: editItem.permissions,
                id: editItem.id
              }}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
