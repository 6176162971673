import React from 'react';

import { Statistic, Divider, Grid, Message } from 'semantic-ui-react';
import MetricsContainer from 'components/containers/Metrics';
import NumberWidget from 'components/NumberWidget';
import DonutChart from 'components/charts/Donut';
import SeriesChart from 'components/charts/Series';
import { activityClassification } from 'utils/analytics';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { hasPermissions } from 'utils/rbac';

function processActivityClassification(data) {
  if (!data['activityStatus.id']) return [];
  return data['activityStatus.id'].map((obj) => {
    const classification = activityClassification.find((c) => c.id === obj.id);
    return {
      key: obj.id,
      name: classification ? classification.name : 'Unknown',
      count: obj.count
    };
  });
}

@inject('me')
@observer
export default class Home extends React.Component {
  render() {
    const { me } = this.props;
    if (!hasPermissions(me.user, 'surveyMetrics', 'read'))
      return <Message content="No permission to access this view" />;
    return (
      <div>
        <Statistic.Group
          size="large"
          style={{ justifyContent: 'space-between' }}
        >
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="participants"
            interval={false}
          >
            <NumberWidget title="Total Participants" field="count" />
          </MetricsContainer>

          <MetricsContainer
            filterType="analytics"
            noContainer
            type="participants"
            interval={false}
            query={{ withData: true }}
          >
            <NumberWidget title="With Survey Data" field="count" />
          </MetricsContainer>
          {/*
          <Statistic>
            <Statistic.Value>{stats.participantsWithFitbit}</Statistic.Value>
            <Statistic.Label>With Fitbit Data</Statistic.Label>
          </Statistic>
          */}
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="participants"
            interval={false}
            query={{ withData: false }}
          >
            <NumberWidget title="Without Survey Data" field="count" />
          </MetricsContainer>
        </Statistic.Group>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {/*
        <Divider />
        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column width={2} />
            <Grid.Column width={12}>
              <Header as="h2" textAlign="center">
                Subject States
              </Header>
              <Divider hidden />
              <Divider hidden />
              <DonutChart data={subjectStatusBreakdown} />
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>
        </Grid>
        */}
        {false && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Divider hidden />
                <MetricsContainer
                  filterType="analytics"
                  title="Subject Activity Classification"
                  interval={false}
                  type="participants"
                  entities={['activityStatus.id']}
                  processData={processActivityClassification}
                >
                  <DonutChart x="name" y="count" height={300} />
                </MetricsContainer>
                <Divider hidden />
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {/*
        <Divider />
        <Divider hidden />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={2} />
            <Grid.Column width={12}>
              <Header as="h2" textAlign="center">
                Data Completeness
              </Header>
              <Divider hidden />
              <Divider hidden />
              <DonutChart data={dataCompletenessBreakdown.reverse()} />
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        */}
        <Divider hidden />
        <MetricsContainer
          title={'Enrollment Over Time'}
          filterType="analytics"
          interval={(timeRange) => {
            if (!timeRange) return 'month';
            const days = moment(timeRange.to).diff(timeRange.from, 'day');
            if (days > 90) {
              return 'month';
            }
            if (days > 32) {
              return 'week';
            }
            return 'day';
          }}
          type="participants"
        >
          <SeriesChart x="ts" y="count" />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {/*
        <Divider />
        <Divider hidden />
        <Header as="h2" textAlign="center">
          Intervention List
        </Header>
        <ParticipantsTable
          disablePagination
          disableFiltering
          //items={enrichedSummaries.filter((i) => !i.isActive).slice(0, 7)}
        />
        <Divider hidden />
        */}
      </div>
    );
  }
}
