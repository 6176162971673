import React from 'react';
import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import { Form, Input } from 'semantic-ui-react';
import { required } from 'utils/validate';
import { Form as FinalForm, Field } from 'react-final-form';

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export default (props) => {
  const initialValues = props.initialValues;
  if (initialValues.introTexts) {
    initialValues.hasIntroTexts = true;
  }
  if (initialValues.cardTexts) {
    initialValues.hasCardTexts = true;
  }

  function handleFormSubmit(body) {
    if (body.cardTexts && !body.hasCardTexts) {
      body.cardTexts = {};
    }
    if (body.introTexts && !body.hasIntroTexts) {
      body.introTexts = {};
    }

    if (Array.isArray(body.schedulingDayOffsets)) {
      body.schedulingDayOffsets = body.schedulingDayOffsets.join(', ');
    }

    delete body.hasCardTexts;
    delete body.hasIntroTexts;

    props.onSubmit(body);
  }

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      initialValues={props.initialValues}
      render={({ handleSubmit, errors }) => (
        <Form
          id={props.id}
          error={Object.keys(errors).length > 0}
          size="large"
          onSubmit={handleSubmit}
        >
          <Form.Field>
            <label>Title</label>
            <Field
              name="title"
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />
            {!props.isUpdate && <label>Type</label>}
            {!props.isUpdate && (
              <Field
                name="type"
                component={FormInput}
                as={Form.Select}
                options={[
                  {
                    text: 'Single Step',
                    value: 'SingleStepTask'
                  },
                  {
                    text: 'Multi Step',
                    value: 'MultiStepTask'
                  }
                ]}
                type="text"
                validate={required}
              />
            )}
            <Form.Group widths={2}>
              <Field
                name="startTimeMs"
                component={FormInput}
                label="Start Time"
                fluid
                as={Form.Input}
                type="number"
                validate={required}
              />
              <Field
                name="expirationTimeMs"
                label="Expiration Time"
                component={FormInput}
                as={Form.Input}
                fluid
                type="number"
                validate={required}
              />
            </Form.Group>
            <label>Scheduled Days</label>
            <Field
              name="schedulingDayOffsets"
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />
            <br />

            <label>Definition - Card Texts</label>
            <Field
              as={FormCheckbox}
              label="Enable Card Texts"
              name="hasCardTexts"
              component={Form.Checkbox}
            />
            <Condition when="hasCardTexts" is={true}>
              <label>Title</label>
              <Field
                name="cardTexts.title"
                component={FormInput}
                as={Input}
                type="text"
                validate={required}
              />
              <label>Body</label>
              <Field
                name="cardTexts.body"
                component={FormInput}
                as={Input}
                type="text"
                validate={required}
              />
              <Form.Group widths={2}>
                <Field
                  name="cardTexts.start"
                  component={FormInput}
                  as={Form.Input}
                  fluid
                  label="Start"
                  type="text"
                  validate={required}
                />
                <Field
                  name="cardTexts.byline"
                  component={FormInput}
                  as={Form.Input}
                  label="By Line"
                  fluid
                  type="text"
                  validate={required}
                />
              </Form.Group>
            </Condition>
            <label>Definition - Intro Texts</label>
            <Field
              as={FormCheckbox}
              label="Enable Intro Texts"
              name="hasIntroTexts"
              component={Form.Checkbox}
            />

            <Condition when="hasIntroTexts" is={true}>
              <label>Title</label>
              <Field
                name="introTexts.title"
                component={FormInput}
                as={Input}
                type="text"
                validate={required}
              />
              <label>Body</label>
              <Field
                name="introTexts.body"
                component={FormInput}
                as={Input}
                type="text"
                validate={required}
              />
              <Form.Group widths={2}>
                <Field
                  name="introTexts.skip"
                  component={FormInput}
                  as={Form.Input}
                  label="Skip"
                  fluid
                  type="text"
                  validate={required}
                />
                <Field
                  name="introTexts.confirm"
                  component={FormInput}
                  as={Form.Input}
                  label="Confirm"
                  type="text"
                  fluid
                  validate={required}
                />
              </Form.Group>
            </Condition>
          </Form.Field>
        </Form>
      )}
    />
  );
};
