import React from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { reaction } from 'mobx';
import List from './List';

import {
  Container,
  Divider,
  Header,
  Loader,
  Segment,
  Dimmer,
  Message,
  Dropdown
} from 'semantic-ui-react';

@inject('studies', 'appSession')
@observer
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    const { appSession, studies } = props;

    this.state = {
      study: studies.items.length ? studies.items[0] : null
    };

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        studies.list().then(() => {
          const { items } = this.props.studies;
          this.setState({ study: items[0] });
        });
      }
    );
  }

  componentDidMount() {
    this.props.studies.list().then(() => {
      const { items } = this.props.studies;
      this.setState({ study: items[0] });
    });
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    const { studies } = this.props;
    const listStatus = studies.getStatus('list');

    return (
      <Container>
        <Header style={{ float: 'left' }} as="h2" className="page-title">
          Task Definitions{' '}
        </Header>
        <Divider hidden />
        <div
          style={{
            marginLeft: '10px',
            float: 'right',
            marginTop: '-8px'
          }}
        >
          <label>
            <span style={{ marginRight: '10px' }} as={'h4'}>
              Studies:
            </span>
            <Dropdown
              style={{
                width: '180px'
              }}
              onChange={(e, { value }) => {
                this.setState({
                  study: studies.items.find((item) => item.data.id === value)
                });
              }}
              value={this.state.study && this.state.study.data.id}
              selection
              options={studies.items.map(({ data }) => {
                return {
                  text: data.name,
                  value: data.id
                };
              })}
              button
              compact
            />
          </label>
        </div>
        <div style={{ clear: 'left' }}>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}

          {listStatus.success &&
            !studies.items.length && (
              <Message>
                You need to create a Study before you created Task definitions{' '}
                <Link to="/studies">Go to studies</Link>
              </Message>
            )}

          {this.state.study && <List studyId={this.state.study.data.id} />}
        </div>
      </Container>
    );
  }
}
