import { NavLink } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';

@observer
export default class Link extends React.Component {
  render() {
    const { to, ...props } = this.props;
    return (
      <NavLink
        isActive={(match, location) => {
          return location.pathname === to;
        }}
        to={to}
        {...props}
      />
    );
  }
}
