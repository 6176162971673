import moment from 'moment';
import { format } from 'd3-format';
import { max } from 'lodash';

const formatNumber = format(',');
const bigNumber = format('.2s');

export function selectYFormatter(data, field) {
  const maxY = max(data.map((o) => o[field]));
  if (maxY < 100) {
    return (y) => {
      return y;
    };
  }
  if (maxY > 1100) {
    return bigNumber;
  }
  return formatNumber;
}

export function formatDateTickValues(date, index, list) {
  const ref = list[index - 1] || list[index + 1];
  const current = moment(date);

  if (ref && moment(ref).year() != current.year()) {
    return current.format('YYYY');
  }
  return current.format('M/D');
}

export const activityClassification = [
  {
    id: 'survey_data_insufficient',
    name: 'Low Survey Data',
    color: 'orange',
    icon: 'wait'
  },
  {
    id: 'fitbit_data_insufficient',
    name: 'Low Wearable Data',
    color: 'orange',
    icon: 'battery low'
  },
  {
    id: 'fitbit_disconnected',
    name: 'Wearable Disconnected',
    color: 'orange',
    icon: 'plug'
  },
  {
    id: 'fitbit_error',
    name: 'Wearable Error',
    color: 'red',
    icon: 'warning sign'
  }
];

export const colorScale = [
  '#00FFB0',
  '#13009B',
  '#B3B3B3',
  '#E6E6E6',
  '#000078',
  '#060439',
  '#222222'
];
