import React from 'react';
import { Form, Message, Modal, Button } from 'semantic-ui-react';
import { mergeWith, set } from 'lodash';

const rolesOptions = [
  { key: 'admin', text: 'Admin', value: 'super_admin' },
  { key: 'invitee', text: 'Invitee', value: 'invitee' },
  { key: 'consumer', text: 'Consumer', value: 'consumer' }
];

export default class EditUser extends React.Component {
  static defaultProps = {
    initialValues: {}
  };

  state = {
    open: false,
    formValues: {
      ...this.props.initialValues
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues }
      });
    }
  }

  handleSubmit = () => {
    this.setState({
      submitted: true
    });

    return this.props.organization
      .updateUser(this.state.formValues)
      .then((err) => {
        console.log('here', err);
        if (err instanceof Error) return;
        this.setState({
          formValues: this.props.initialValues,
          open: false,
          submitted: false
        });
      });
  };

  setField(name, value, convertEmpty = true) {
    const parsedValue =
      convertEmpty && typeof value === 'string' && value.length === 0
        ? null
        : value;

    this.setState({
      submitted: false,
      formValues: mergeWith(
        {},
        this.state.formValues,
        set({}, name, parsedValue),
        (objValue, srcValue) => {
          if (Array.isArray(objValue)) return srcValue;
        }
      )
    });
  }

  render() {
    const { users, initialValues, trigger } = this.props;
    const { formValues = {}, submitted, open } = this.state;

    const isUpdate = !!initialValues.id;
    const status = this.props.organization.getStatus('updateUser');

    const { contact = {} } = formValues;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>{isUpdate ? `Edit User` : 'New User'}</Modal.Header>
        <Modal.Content>
          <Form
            id="edit-user-form"
            autoComplete="disabled"
            error={submitted && Boolean(status.error)}
            onSubmit={() => this.handleSubmit()}
          >
            {status.error && <Message error content={status.error.message} />}
            <Form.Input
              value={formValues.email || ''}
              required
              autoComplete="disabled"
              name="email"
              label="E-mail"
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
            {isUpdate && (
              <Form.Input
                value={formValues.password || ''}
                autoComplete="disabled"
                name="password"
                label="Set Password"
                type="password"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            form="edit-user-form"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
