import React from 'react';
import Wizard from 'components/Wizard';
import Modal from 'components/Modal';
// import Wrapper from './Wrapper';

import { Input, TextArea } from 'semantic-ui-react';

import FormInput from 'components/FormInput';
import { Field } from 'react-final-form';
import { required, email } from 'utils/validate';

export default class StudyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      showNext: true
    };
    this.wizard = React.createRef();
    this.modal = React.createRef();
  }

  open = () => {
    this.modal.current.open();
  };

  close = () => {
    this.modal.current.close();
  };

  handleCreate = (body) => {
    const { studies } = this.props;
    return studies.create(body).then((result) => {
      if (result instanceof Error) return;
      this.close();
    });
  };

  handleUpdate = (body) => {
    const { studies } = this.props;
    return studies.update(body).then((err) => {
      if (err instanceof Error) return;
      this.close();
    });
  };

  render() {
    const { studies } = this.props;
    const { showSubmit, showNext, showPrev } = this.state;
    const createStatus = studies.getStatus('create');
    const updateStatus = studies.getStatus('update');

    const actions = [
      showPrev && {
        content: 'Prev',
        onClick: () => this.wizard.current.previous()
      },
      showSubmit && {
        content: 'Submit',
        loading: (createStatus || updateStatus).request === true,
        primary: true,
        onClick: () => this.wizard.current.next()
      },
      showNext && {
        content: `Next`,
        primary: true,
        onClick: () => {
          this.wizard.current.next();
        }
      }
    ].filter(Boolean);

    return (
      <Modal
        ref={this.modal}
        title={'Create Study'}
        actions={actions}
        onClose={() => {
          if (this.props.onDone) this.props.onDone();

          this.setState({
            currentPage: 0,
            showNext: true,
            showSubmit: false,
            showPrev: false
          });
        }}
      >
        <Wizard
          onSubmit={
            this.props.initialValues.id ? this.handleUpdate : this.handleCreate
          }
          ref={this.wizard}
          onNext={(page, isFinalPage) => {
            this.setState({
              currentPage: page,
              showPrev: true,
              showSubmit: isFinalPage,
              showNext: !isFinalPage
            });
          }}
          onPrev={(page) => {
            this.setState({
              currentPage: page,
              showSubmit: false,
              showNext: true
            });
          }}
          initialValues={this.props.initialValues}
        >
          <Wizard.Page>
            <h4>Study Information</h4>
            <Field
              name="name"
              label="Name"
              required
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />
            <Field
              label="Description"
              name="description"
              required
              component={FormInput}
              as={TextArea}
              type="text"
              validate={required}
            />
            <Field
              label="Duration in Days"
              name="durationDays"
              required
              component={FormInput}
              as={Input}
              type="number"
              validate={required}
            />
            <Field
              label="Number of Subjects (Optional)"
              name="numSubjects"
              component={FormInput}
              as={Input}
              type="number"
            />
            <Field
              label="Actigraph Study ID (Optional)"
              name="actigraphStudyId"
              component={FormInput}
              as={Input}
              type="number"
            />
            <Field
              label="Study Code (Optional)"
              name="code"
              component={FormInput}
              as={Input}
              type="text"
            />
            <Field
              label="Adherence Ruleset ID (Optional)"
              name="adherenceRulesetId"
              component={FormInput}
              as={Input}
              type="string"
            />
          </Wizard.Page>
          <Wizard.Page>
            <h4>Consent Copy</h4>
            <Field
              label="Title"
              name="consent.title"
              required
              component={FormInput}
              as={Input}
              type="string"
              validate={required}
            />
            <Field
              label="Body"
              name="consent.body"
              required
              component={FormInput}
              as={TextArea}
              type="string"
              validate={required}
            />
            <Field
              label="Confirmation"
              name="consent.confirmation"
              required
              component={FormInput}
              as={Input}
              type="string"
              validate={required}
            />
          </Wizard.Page>
          <Wizard.Page>
            <h4>Clinician Contact Info</h4>
            <Field
              label="Full Name"
              name="contact.name"
              component={FormInput}
              required
              as={Input}
              type="string"
              validate={required}
            />
            <Field
              label="Email"
              name="contact.email"
              required
              component={FormInput}
              as={Input}
              type="email"
              validate={email}
            />
            <Field
              label="Phone Number"
              name="contact.phone"
              component={FormInput}
              as={Input}
              type="tel"
            />
            <Field
              label="Address"
              name="contact.address"
              component={FormInput}
              as={Input}
              type="string"
            />
          </Wizard.Page>
        </Wizard>
      </Modal>
    );
  }
}
