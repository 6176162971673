import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import Role from './item';
import appSession from 'stores/AppSession';

export default class RolesStore extends BaseStore {
  @observable items = [];

  @action
  list(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/roles/${appSession.organization.id}`
    })
      .then((resp) => {
        this.items = resp.data.map((role) => new Role(role));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/roles/${appSession.organization.id}`,
      body
    })
      .then((resp) => {
        const role = new Role(resp.data);
        if (!this.items.length) {
          appSession.setRole(role);
        }

        this.items.push(role);

        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/roles/${appSession.organization.id}/${id}`,
      body: rest
    })
      .then(({ data }) => {
        const index = this.items.findIndex((item) => item.id == data.id);
        this.items.splice(index, 1, data);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  delete(role, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/roles/${appSession.organization.id}/${role.id}`
    })
      .then(() => {
        this.items.replace(this.items.filter((item) => item.id !== role.id));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
