import React from 'react';
import { Form, Select } from 'semantic-ui-react';
import EditRoleCheckbox from './EditRoleCheckbox';

export default (props) => {
  const { roles, userId } = props;
  return (
    <Form size="large">
      {roles.items.map((item) => {
        return <EditRoleCheckbox role={item} userId={userId} />;
      })}
    </Form>
  );
};
