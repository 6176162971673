import React from 'react';
import { observer, inject } from 'mobx-react';
import PageCenter from 'components/PageCenter';
import PageLoader from 'components/PageLoader';
import FatalError from 'components/FatalError';
import { reaction } from 'mobx';

@inject('appSession', 'me', 'organizations', 'routing')
@observer
export default class Ensure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
    this.handleLoading();
    this.disposeReaction = reaction(
      () => props.appSession.token,
      () => {
        this.handleLoading();
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  handleLoading() {
    const { appSession, me, organizations } = this.props;
    if (appSession.token && !me.user) {
      Promise.all([me.fetch(), organizations.list()]).then((results) => {
        const error = results.find((result) => result instanceof Error);
        if (error) {
          this.setState({
            error: error.message
          });
          return;
        }
        if (!organizations.items.length) {
          if (me.user.role !== 'admin') {
            this.setState({
              error: 'You are not a member or any active organization'
            });
            return;
          } else {
            this.props.routing.push('/organizations');
          }
        }
        const lastUsedOrganizationId = window.localStorage.organization;

        const selectedOrganization =
          (lastUsedOrganizationId &&
            organizations.items.find(
              (item) => item.id === lastUsedOrganizationId
            )) ||
          organizations.items[0];

        appSession.setOrganization(selectedOrganization);
        appSession.setLoaded();
      });
      return;
    }
    appSession.setLoaded();
  }

  render() {
    const { appSession } = this.props;

    if (this.state.error) {
      return <FatalError message={this.state.error} />;
    }

    if (!appSession.loaded) {
      return (
        <PageCenter>
          <PageLoader />
        </PageCenter>
      );
    }

    return this.props.children
      ? React.Children.only(this.props.children)
      : null;
  }
}
