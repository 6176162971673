import 'theme/semantic.less';

import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import { hot } from 'react-hot-loader';

import Protected from 'components/routes/Protected';
import Index from 'components/routes/Index';

import Analytics from './screens/Analytics';
import Participants from './screens/Participants';
import Participant from './screens/Participant';
import Organizations from './screens/Organizations';
import Roles from './screens/Roles';
import Studies from './screens/Studies';
import Settings from './screens/Settings';
import Team from './screens/Team';
import TaskDefinitions from './screens/TaskDefinitions';
import Mutations from './screens/Mutations';
import Devices from './screens/Devices';
import Exports from './screens/Exports';
import Imports from './screens/Imports';
import Enrollments from './screens/Enrollments';
import Users from './screens/Users';

import Login from './screens/Auth/Login';
import Logout from './screens/Auth/Logout';
import Register from './screens/Auth/Register';
import AcceptInvite from './screens/Auth/AcceptInvite';
import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';
import ChangePassword from './screens/Auth/ChangePassword';
import Authenticated from './screens/Auth/Authenticated';

import DocsEventsAPI from './screens/Docs/EventsAPI';

const App = () => (
  <Switch>
    <Index
      exact
      path="/"
      getComponent={(loggedIn) => {
        if (loggedIn) return Participants;
        return Login;
      }}
    />
    <Route exact path="/login" component={Login} />
    <Route exact path="/accept-invite" component={AcceptInvite} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Route exact path="/change-password" component={ChangePassword} />
    <Route exact path="/authenticated" component={Authenticated} />

    <Protected path="/analytics" component={Analytics} />
    <Protected path="/participants/:subjectKey" component={Participant} />
    <Protected path="/participants" component={Participants} />
    <Protected path="/organizations" component={Organizations} />
    <Protected path="/roles" component={Roles} />
    <Protected path="/settings" component={Settings} />
    <Protected path="/team" component={Team} />
    <Protected path="/users" component={Users} />
    <Protected path="/studies" component={Studies} />
    <Protected path="/mutations" component={Mutations} />
    <Protected path="/enrollments" component={Enrollments} />
    <Protected path="/exports" component={Exports} />
    <Protected path="/imports" component={Imports} />
    <Protected path="/devices" component={Devices} />
    <Protected path="/task-definitions" component={TaskDefinitions} />

    <Route exact path="/docs/events-api-v2" component={DocsEventsAPI} />
    <Redirect to="/" />
  </Switch>
);

export default hot(module)(App); //eslint-disable-line
