import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import User from './item';

export default class UsersStore extends BaseStore {
  @observable items = [];

  @action
  list(organization, statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/users?organizationId=${organization.id}`
    })
      .then((resp) => {
        this.items = resp.data.map((user) => new User(user));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/users/${id}`,
      body: rest
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.id, item);
        return this.fetchItems({}, false).then(() => {
          status.success();
          return item;
        });
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
