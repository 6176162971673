import React from 'react';
import { Segment, Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import ApplyForm from './Form';
import { Form } from 'react-final-form';

import { parseToken } from 'utils/token';

@inject('auth')
@observer
export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    const { token } = queryString.parse(props.location.search);
    this.state = {
      token,
      jwt: token && parseToken(token),
      error: null
    };
  }

  onSubmit = (body) => {
    return this.props.auth
      .setPassword(
        {
          ...body,
          token: this.state.token
        },
        'set-password'
      )
      .then((result) => {
        if (result instanceof Error) {
          this.setState({ error: result });
        } else {
          this.setState({ error: null });
        }
      });
  };

  render() {
    const { token, jwt, error } = this.state;
    const status = this.props.auth.getStatus('set-password');
    return (
      <PageCenter>
        <LogoTitle title="Change Password" />
        <Segment.Group>
          <Segment padded>
            <p>
              Your password has expired. In order to login, please create a new
              password:
            </p>
            {error && <Message error content={error.message} />}
            {(!token || !jwt) && (
              <p>
                <Message error size="huge">
                  <Message.Header>No valid token found</Message.Header>
                  <Message.Content>
                    Please try <a href="/login">Logging In Again</a>.
                  </Message.Content>
                </Message>
              </p>
            )}
            {status.success && (
              <Message info>
                <Message.Header>Your password has been changed!</Message.Header>
                <p>
                  From now on you can use your new password.{' '}
                  <a href="/">Open Dashboard</a>
                </p>
              </Message>
            )}
            {!status.success && token && jwt && (
              <Form onSubmit={this.onSubmit} render={ApplyForm} />
            )}
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}
