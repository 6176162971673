import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import TimeAgo from 'react-timeago';
import request from 'utils/request';

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Button
} from 'semantic-ui-react';

@inject('studies', 'appSession')
@observer
export default class ExportsList extends React.Component {
  constructor(props) {
    super(props);
    const { appSession, studies } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        studies.list();
      }
    );
  }

  componentDidMount() {
    this.props.studies.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  downloadUrl(studyId, urlType) {
    request({
      method: 'GET',
      path: '/1/exports/download',
      query: { studyId, urlType }
    })
      .then((result) => {
        document.location.href = result.url;
      })
      .catch((e) => {
        alert('Error: ' + e.message);
      });
  }

  render() {
    const { studies } = this.props;
    const listStatus = studies.getStatus('list');
    const items = studies.items.filter((i) => !!i.data.lastExport);
    return (
      <Container>
        <Header as="h2">Exports</Header>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Study Name</Table.HeaderCell>
                <Table.HeaderCell width={1}>Participants</Table.HeaderCell>
                <Table.HeaderCell width={2}>Stats</Table.HeaderCell>
                <Table.HeaderCell width={2}>Disk Size</Table.HeaderCell>
                <Table.HeaderCell width={2}>Last Export</Table.HeaderCell>
                <Table.HeaderCell width={4}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No studies exported yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                const {
                  createdTs,
                  numSurveyEvents,
                  numWearableEvents,
                  numParticipants,
                  sizeUncompressed,
                  sizeTarGz
                } = item.data.lastExport;
                const { id, name } = item.data;
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{numParticipants}</Table.Cell>
                    <Table.Cell>
                      {numSurveyEvents} survey events, {numWearableEvents}{' '}
                      wearable days
                    </Table.Cell>
                    <Table.Cell>
                      {sizeUncompressed} ({sizeTarGz} compressed)
                    </Table.Cell>
                    <Table.Cell>
                      <TimeAgo date={Math.round(createdTs * 1000)} />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        as="a"
                        onClick={() => this.downloadUrl(id, 'downloadUrlTarGz')}
                        target="_blank"
                        primary
                        icon="download"
                        content=".tar.gz"
                      />
                      <Button
                        as="a"
                        onClick={() => this.downloadUrl(id, 'downloadUrlZip')}
                        target="_blank"
                        primary
                        icon="download"
                        content=".zip"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
      </Container>
    );
  }
}
