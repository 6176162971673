import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import logoInverted from 'assets/logo_inverted.svg';
import twitterIcon from 'assets/twitter_social_icon.svg';
import linkedinIcon from 'assets/linkedin_social_icon.svg';
import pillGraphic from 'assets/pill_graphic.svg';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  position: relative;
  background: #060439;
  margin-top: 100px;
  padding: 50px 0 50px 0;
  color: #fff;

  .primary-links {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 0.05em;

    a {
      color: #00FFB0;
    }
  }

  .secondary-links {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    li {
      display: inline-block;
      margin-left: 45px;
    }

    a {
      color: #fff;
    }
  }

  &:before {
    background: url( ${pillGraphic} );
    position: absolute;
    height: 20px;
    width: 44px;
    top: -10px;
    left: 50%;
    margin-left: -22px;
    content: '';
  }
`;

export default class Footer extends React.Component {
  render() {
    return (
      <FooterWrapper>
        <Container>
          <Grid>
            <Grid.Row columns='2'>
              <Grid.Column>
                <ul className="primary-links">
                  <li><a href="#">Home</a></li>
                  <li><a href="#">About</a></li>
                  <li><a href="#">Applications</a></li>
                  <li><a href="#">Resources</a></li>
                  <li><a href="#">Contact</a></li>
                </ul>
              </Grid.Column>
              <Grid.Column style={{ textAlign: 'right' }}>
                <img
                  style={{ width: '170px', marginTop: '30px' }}
                  alt="Litmus Health"
                  className="logo"
                  src={`${logoInverted}`}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='1' >
              <Grid.Column style={{ textAlign: 'right', marginTop: '-15px' }}>
                <a href="https://twitter.com/litmushealth" style={{ marginRight: '30px' }}>
                  <img
                    style={{ width: '31px' }}
                    alt="Twitter Social Icon"
                    className="logo"
                    src={`${twitterIcon}`}
                  />
                </a>
                <a href="https://www.linkedin.com/company/litmus-health">
                  <img
                    style={{ width: '30px' }}
                    alt="Linkedin Social Icon"
                    className="logo"
                    src={`${linkedinIcon}`}
                  />
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='1'>
              <Grid.Column>
                <b style={{ textTransform: 'uppercase', letterSpacing: '0.05em', color: 'rgba(255,255,255,0.5)' }}>&copy;2018 Litmus Health</b>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: '-15px' }}>
              <Grid.Column width='5'>
                <span style={{ color: 'rgba(255,255,255,0.5)' }}>Proudly made in Austin, TX and NYC.</span>
              </Grid.Column>
              <Grid.Column width='11' style={{ textAlign: 'right' }}>
                <ul className="secondary-links">
                  <li><a href="#">Blog</a></li>
                  <li><a href="#">Media</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Terms of Service</a></li>
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </FooterWrapper>
    );
  }
}
