import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import TaskDefinition from './item';
import appSession from 'stores/AppSession';
import { omit } from 'lodash';

export default class TaskDefinitions extends BaseStore {
  @observable items = [];
  @observable registry = observable.map();

  getItem(id) {
    const task = this.registry.get(id);
    if (!task) return null;
    return task;
  }

  @action
  list({ studyId }, statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/task-definitions',
      query: {
        studyId,
        organizationId: appSession.organization.id
      }
    })
      .then((resp) => {
        this.items = resp.data.map((item) => {
          const taskItem = new TaskDefinition(item);
          this.registry.set(item.id, taskItem);
          return taskItem;
        });
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  get(id, statusKey = 'get') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/task-definitions/${id}`
    })
      .then(({ data }) => {
        const taskItem = new TaskDefinition(data);
        this.registry.set(data.id, taskItem);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/task-definitions',
      body: {
        ...body
      }
    })
      .then(({ data }) => {
        const taskItem = new TaskDefinition(data);
        this.registry.set(data.id, taskItem);
        this.items.push(taskItem);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/task-definitions/${body.id}`,
      body: omit(body, [
        'id',
        'organizationId',
        'updatedAt',
        'createdAt',
        'stepCount'
      ])
    })
      .then(({ data }) => {
        const taskItem = new TaskDefinition({
          ...data,
          stepCount: data.steps.length
        });

        this.registry.set(data.id, taskItem);
        const index = this.items.findIndex((item) => item.data.id === data.id);
        this.items.splice(index, 1, taskItem);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  delete(body, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/task-definitions/${body.id}`
    })
      .then(() => {
        this.registry.delete(body.id);
        this.items.replace(
          this.items.filter((item) => item.data.id !== body.id)
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
