import { observable, action } from 'mobx';

function updateTimeRangeWithDefault(timeRange, defaultTimeRangeFrom) {
  if (!timeRange) {
    console.log(defaultTimeRangeFrom);
    return {
      from: defaultTimeRangeFrom,
      to: Date.now()
    };
  }
  return timeRange;
}

export default class Analytics {
  @observable timeRange;
  @observable subject;
  @observable defaultTimeRangeFrom;

  @action
  setTimeRange(timeRange) {
    this.timeRange = updateTimeRangeWithDefault(
      timeRange,
      this.defaultTimeRangeFrom
    );
  }

  @action
  setDefaultTimeRangeFrom(from) {
    this.defaultTimeRangeFrom = from;
    this.timeRange = updateTimeRangeWithDefault(this.timeRange, from);
  }

  @action
  setSubject(subject) {
    this.subject = subject;
  }

  @action
  reset() {
    this.setSubject(undefined);
    this.setTimeRange(undefined);
    this.setTimeRange(undefined);
  }
}
