import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import AppWrapper from 'components/AppWrapper';
import EditUser from 'components/modals/EditUser';
import { startCase } from 'lodash';
import moment from 'moment';
import request from 'utils/request';
import { renderUserType } from 'utils/rbac';

import {
  Container,
  Divider,
  Header,
  Table,
  Button,
  Label
} from 'semantic-ui-react';

@inject('appSession', 'me')
@observer
export default class Users extends React.Component {
  state = {};

  constructor(props) {
    super(props);
  }

  fetch() {
    const { organization } = this.props.appSession;
    organization.fetchUsers('list');
  }

  componentDidMount() {
    this.fetch();
  }

  unfreeze(user) {
    if (!confirm('Are you sure you want to unfreeze this user?')) {
      return;
    }
    const { organization } = this.props.appSession;
    request({
      method: 'POST',
      path: `/1/organizations/${organization.id}/members/${user.id}/unfreeze`
    })
      .then(() => {
        this.fetch();
      })
      .catch((e) => {
        alert('Error: ' + e.message);
      });
  }

  render() {
    const { organization } = this.props.appSession;

    return (
      <AppWrapper>
        <Container style={{ marginTop: '50px' }}>
          <Header as="h2" className="page-title">
            Users
          </Header>
          <Divider hidden />
          <div className="list">
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Identifier</Table.HeaderCell>
                  <Table.HeaderCell>User Type</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Last Login</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {!organization.users.length && (
                  <Table.Row>
                    <Table.Cell>No users yet</Table.Cell>
                  </Table.Row>
                )}
                {organization.users.map((user) => {
                  return (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Header as="h4">
                          <Header.Content>
                            {user.name || '-'}
                            <Header.Subheader>{user.email}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{renderUserType(user)}</Table.Cell>
                      <Table.Cell>
                        {startCase(user.source || 'platform')}
                      </Table.Cell>
                      <Table.Cell>{startCase(user.status)}</Table.Cell>
                      <Table.Cell collapsing>
                        {moment(user.createdAt).format('LL')}
                      </Table.Cell>
                      <Table.Cell>
                        {organization && (
                          <EditUser
                            initialValues={{
                              email: user.email,
                              id: user.id
                            }}
                            organization={organization}
                            trigger={<Button basic icon="edit" />}
                          />
                        )}
                        {user.status === 'frozen' && (
                          <Button
                            onClick={() => this.unfreeze(user)}
                            basic
                            content="Unfreeze"
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Container>
      </AppWrapper>
    );
  }
}
