import React, { Component } from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import DonutChart from 'components/charts/Donut';
import SeriesChart from 'components/charts/Series';
import MetricsContainer from 'components/containers/Metrics';
import StackedBarChart from 'components/charts/StackedBar';

function processPie(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.namespaceCategory.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });
  const a = Object.keys(namespaces).map((key) => {
    return {
      y: namespaces[key],
      x: key
    };
  });
  return a;
}

function processStacked(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.namespaceCategory.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });

  const x = Object.keys(namespaces).map((key) => {
    return {
      id: key,
      series: data.map((bucket) => {
        const namespace = bucket.namespaceCategory.find(
          (entity) => entity.id == key
        );
        return {
          x: bucket.ts,
          y: namespace ? namespace.count : 0
        };
      })
    };
  });
  return x;
}

export default class Home extends Component {
  render() {
    const { participant } = this.props;
    return (
      <div>
        <MetricsContainer
          filterType="analytics"
          type="survey"
          title="Survey Events Over Time"
        >
          <SeriesChart
            x="ts"
            y="count"
            type="line"
            markers={
              participant
                ? [
                    {
                      text: 'Enrollment',
                      ts: participant.createdAt || participant.joinedAt
                    }
                  ]
                : []
            }
          />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Divider hidden />
              <MetricsContainer
                filterType="analytics"
                title="Survey Types"
                type="survey"
                entities={['namespaceCategory']}
                processData={processPie}
              >
                <DonutChart height={300} />
              </MetricsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <MetricsContainer
          filterType="analytics"
          style={{ marginBottom: '20px' }}
          title="Survey Category Over Time"
          type="survey"
          entities={['namespaceCategory']}
          processData={processStacked}
        >
          <StackedBarChart
            markers={
              participant
                ? [
                    {
                      text: 'Enrollment',
                      ts: participant.createdAt || participant.joinedAt
                    }
                  ]
                : []
            }
          />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </div>
    );
  }
}
