import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { reaction } from 'mobx';

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Menu
} from 'semantic-ui-react';

import Link from 'components/Link';
import SecondaryMenu from 'components/SecondaryMenu';

@inject('mutations', 'appSession')
@observer
export default class MutationsList extends React.Component {
  constructor(props) {
    super(props);
    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
    const { appSession, mutations } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        mutations.list();
      }
    );
  }

  componentDidMount() {
    this.props.mutations.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  render() {
    const { mutations } = this.props;
    const listStatus = mutations.getStatus('list');

    return (
      <Container>
        <Header as="h2">Audit Trail</Header>
        <SecondaryMenu pointing secondary>
          <Menu.Menu position="left">
            <Menu.Item name="Organization Level" as={Link} to="/mutations" />
            <Menu.Item name="User Level" as={Link} to="/mutations/users" />
          </Menu.Menu>
        </SecondaryMenu>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Actor</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!mutations.items.length && (
                <Table.Row>
                  <Table.Cell>No mutations yet</Table.Cell>
                </Table.Row>
              )}
              {mutations.items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Header.Content>
                        {item.actor.name}
                        <Header.Subheader>{item.actor.email}</Header.Subheader>
                      </Header.Content>
                    </Table.Cell>
                    <Table.Cell>
                      {item.actionDescription
                        ? item.actionDescription.toLowerCase()
                        : `${
                            item.action
                          } a ${item.collectionName.toLowerCase()}`}
                    </Table.Cell>

                    <Table.Cell>
                      {moment(item.createdAt).format('LLL')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
      </Container>
    );
  }
}
