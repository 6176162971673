import React from 'react';
import { observer, inject } from 'mobx-react';

import { Button, Form, TextArea, Message, Modal } from 'semantic-ui-react';
import { emailRegexp } from 'utils/validate';
import request from 'utils/request';

@inject('appSession')
@observer
export default class InviteForm extends React.Component {
  state = {
    validEmails: [],
    invalidEmails: []
  };

  handleTextAreaChange = (e, { value }) => {
    const values = value
      .toLowerCase()
      .split(/[\s,;\t\n]+/)
      .map((str) => str.trim())
      .filter(Boolean);

    const validEmails = [];
    const invalidEmails = [];
    values.forEach((text) => {
      if (text.match(emailRegexp)) {
        validEmails.push(text);
      } else {
        invalidEmails.push(text);
      }
    });
    this.setState({
      validEmails,
      invalidEmails
    });
  };

  handleOnSubmit = () => {
    request({
      method: 'POST',
      path: '/1/enrollments/invite',
      body: {
        studyId: this.props.studyId,
        emails: this.state.validEmails
      }
    }).then(() => {
      this.props.onClose();
      this.props.onSubmit();
    });
  };

  render() {
    const { validEmails } = this.state;
    const { organization } = this.props.appSession;
    const status = organization.getStatus('invite');

    return (
      <React.Fragment>
        <Modal.Header>Enroll participant to study</Modal.Header>
        <Modal.Content>
          {status.error && <Message error content={status.error.message} />}
          <Form onSubmit={this.handleOnSubmit} className={this.props.className}>
            <Form.Field>
              <label>Enter email address of the participant to invite</label>
              <TextArea
                style={{ height: '150px' }}
                name="emails"
                onChange={this.handleTextAreaChange}
                placeholder="Email address seperate by comma or newline .e.g first@litmus.com, second@litmus.com"
              />
            </Form.Field>
            {status.error && <Message error content={status.error.message} />}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.props.onClose}>
            Close
          </Button>
          <Button
            onClick={this.handleOnSubmit}
            primary
            loading={status.request}
            type="submit"
          >
            Invite Members {validEmails.length ? `(${validEmails.length})` : ''}
          </Button>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
