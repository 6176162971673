import React from 'react';
import { Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { omit } from 'lodash';

import Register from './Form';
import { Form } from 'react-final-form';
import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import { getToken, parseToken } from 'utils/token';

@inject('auth', 'routing')
@observer
export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    const token = getToken(props);
    this.state = {
      token,
      jwt: token && parseToken(token)
    };
  }
  handleSubmit = (body) => {
    this.setState({ email: body.email });
    return this.props.auth
      .register({ ...omit(body, ['email']), token: this.state.token })
      .then((result) => {
        if (result instanceof Error) return;
        this.props.routing.push('/');
      });
  };

  render() {
    const status = this.props.auth.getStatus('register');
    const { jwt, token } = this.state;
    return (
      <PageCenter>
        <LogoTitle />

        <div className="content">
          {token && (
            <div className="wrapper">
              <h2>Complete your account</h2>
              {status.error && <Message error content={status.error.message} />}
              <Form
                initialValues={{ email: jwt.email || jwt.sub }}
                onSubmit={this.handleSubmit}
                render={Register}
              />
            </div>
          )}
          {(!token || !jwt) && (
            <div className="wrapper">
              <h2>Complete your account</h2>
              <Message error size="huge">
                <Message.Header>No valid token found</Message.Header>
                <Message.Content>
                  Please ensure you either click the email link in the email
                  <br />
                  or copy paste the link in full.
                </Message.Content>
              </Message>
            </div>
          )}
        </div>
      </PageCenter>
    );
  }
}
