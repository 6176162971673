import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { reaction } from 'mobx';
import request from 'utils/request';

import {
  Container,
  Header,
  Table,
  Loader,
  Dimmer,
  Message,
  Menu,
  Select
} from 'semantic-ui-react';

import Link from 'components/Link';
import SecondaryMenu from 'components/SecondaryMenu';

@inject('appSession')
@observer
export default class MutationsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mutations: []
    };

    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
    const { appSession } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        const { organization } = this.props.appSession;
        organization
          .fetchMembers('members')
          .then(() => {
            if (organization.members.length) {
              return this.fetchUserMutations(organization.members[0].id);
            } else {
              this.setState({
                loading: false
              });
            }
          })
          .catch((e) => {
            this.setState({ error: e });
          });
      }
    );
  }

  fetchUserMutations(userId) {
    const { organization } = this.props.appSession;
    this.setState({
      loading: true,
      selectedUser: userId
    });
    return request({
      method: 'GET',
      path: '/1/mutations',
      query: {
        actorId: userId,
        organizationId: organization.id
      }
    })
      .then((resp) => {
        this.setState({
          loading: false,
          mutations: resp.data
        });
      })
      .catch((e) => {
        this.setState({ error: e });
      });
  }

  componentDidMount() {
    const { organization } = this.props.appSession;
    organization
      .fetchMembers('members')
      .then(() => {
        if (organization.members.length) {
          return this.fetchUserMutations(organization.members[0].id);
        } else {
          this.setState({
            loading: false
          });
        }
      })
      .catch((e) => {
        this.setState({ error: e });
      });
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  render() {
    const { appSession } = this.props;
    const { selectedUser, loading, error, mutations } = this.state;

    const members = appSession.organization.members.map((c) => ({
      value: c.id,
      text: c.email
    }));

    return (
      <Container>
        <Header as="h2">Audit Trail</Header>
        <SecondaryMenu pointing secondary>
          <Menu.Menu position="left">
            <Menu.Item name="Organization Level" as={Link} to="/mutations" />
            <Menu.Item name="User Level" as={Link} to="/mutations/users" />
          </Menu.Menu>
        </SecondaryMenu>

        <div className="list" style={{ position: 'relative' }}>
          {loading &&
            !error && (
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            )}

          <label>
            <b>Select User</b>{' '}
            <Select
              value={selectedUser}
              options={members}
              onChange={(e, { value }) => {
                this.fetchUserMutations(value);
              }}
            />
          </label>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Actor</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(!mutations.length || !appSession.organization.members) && (
                <Table.Row>
                  <Table.Cell>No Mutations yet</Table.Cell>
                </Table.Row>
              )}
              {mutations.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Header.Content>
                        {item.actor.name}
                        <Header.Subheader>{item.actor.email}</Header.Subheader>
                      </Header.Content>
                    </Table.Cell>
                    <Table.Cell>
                      {item.actionDescription
                        ? item.actionDescription.toLowerCase()
                        : `${
                            item.action
                          } a ${item.collectionName.toLowerCase()}`}
                    </Table.Cell>

                    <Table.Cell>
                      {moment(item.createdAt).format('LLL')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {error && <Message error content={error.message} />}
        </div>
      </Container>
    );
  }
}
