import { observable } from 'mobx';

import Analytics from './Analytics';
import Participants from './Participants';

class Filters {
  @observable analytics = new Analytics();
  @observable participants = new Participants();
}

export default new Filters();
