import React from 'react';
import { Header, Form, Input } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import AppWrapper from 'components/AppWrapper';

@inject('me')
@observer
export default class Settings extends React.Component {
  render() {
    const { user } = this.props.me;
    return (
      <AppWrapper>
        <Header as="h2" icon="settings" content="Settings" />
        <Form>
          <Input type="text" disabled defaultValue={user.name} />
          <Input type="text" disabled defaultValue={user.email} />
        </Form>
      </AppWrapper>
    );
  }
}
