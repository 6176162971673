import React, { Component } from 'react';

import { Statistic, Divider } from 'semantic-ui-react';
import MetricsContainer from 'components/containers/Metrics';
import StackedBarChart from 'components/charts/StackedBar';
import NumberWidget from 'components/NumberWidget';

function processStacked(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.namespaceCategory.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });

  return Object.keys(namespaces).map((key) => {
    return {
      id: key,
      series: data.map((bucket) => {
        const namespace = bucket.namespaceCategory.find(
          (entity) => entity.id == key
        );
        return {
          x: bucket.ts,
          y: namespace ? namespace.count : 0
        };
      })
    };
  });
}

export default class Home extends Component {
  render() {
    const { participant, disableSurveys } = this.props;
    const { device } = participant;
    return (
      <div>
        <Statistic.Group
          size="large"
          style={{ justifyContent: 'space-between' }}
        >
          <MetricsContainer
            noContainer
            filterType="analytics"
            type="participants"
            interval={false}
            entities={['studyId']}
            processData={(data) => {
              return { count: Object.keys(data.studyId || {}).length };
            }}
          >
            <NumberWidget title="Studies" field="count" />
          </MetricsContainer>
          {!disableSurveys && (
            <MetricsContainer
              filterType="analytics"
              noContainer
              type="survey"
              interval={false}
            >
              <NumberWidget title="Survey Events" field="count" />
            </MetricsContainer>
          )}
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="wearable"
            query={{
              measurementType: 'steps'
            }}
            interval={false}
          >
            <NumberWidget
              title={device.type === 'garmin' ? 'Synced Events' : 'Synced Days'}
              field="count"
            />
          </MetricsContainer>
        </Statistic.Group>
        <Divider hidden />
        <Divider hidden />
        {!disableSurveys && (
          <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <MetricsContainer
              filterType="analytics"
              style={{ marginBottom: '20px' }}
              title="Survey Events Over Time"
              type="survey"
              entities={['namespaceCategory']}
              processData={processStacked}
            >
              <StackedBarChart
                markers={
                  participant
                    ? [
                        {
                          text: 'Enrollment',
                          ts: participant.createdAt || participant.joinedAt
                        }
                      ]
                    : []
                }
              />
            </MetricsContainer>
          </>
        )}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {/*
        <Divider />
        <Divider hidden />
        <Divider hidden />
        <Header as="h2" textAlign="center">
          Fitbit Events Over Time
        </Header>
        <SeriesChart type="line" data={fitbitEventsSeries} />
        */}
      </div>
    );
  }
}
