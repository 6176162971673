import React from 'react';
import { Route } from 'react-router-dom';
import Ensure from 'components/Ensure';

export default class Boot extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Ensure>
              <Component {...props} />
            </Ensure>
          );
        }}
      />
    );
  }
}
