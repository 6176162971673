import React from 'react';
import { observer } from 'mobx-react';
import Modal from 'components/Modal';
import StepForm from 'components/forms/TaskStep';
import { truncate } from 'lodash';
import { getStepTypeLabel } from 'utils/types';

import {
  Container,
  Header,
  Button,
  Loader,
  Segment,
  Dimmer,
  Message,
  List
} from 'semantic-ui-react';

@observer
export default class Home extends React.Component {
  state = {
    showCreateDialog: false
  };

  constructor(props) {
    super(props);
    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
  }

  handleRemove = (step) => {
    this.props.taskDefinition.deleteStep(step);
  };

  handleCreate = (body) => {
    return this.props.taskDefinition.addStep(body).then((result) => {
      if (result instanceof Error) return;
      const dialog = this.createDialog.current;
      if (dialog) dialog.close();
    });
  };

  handleUpdate = (body) => {
    return this.props.taskDefinition.updateStep(body).then((err) => {
      if (err instanceof Error) return;
      const dialog = this.editDialog.current;
      if (dialog) dialog.close();
    });
  };

  render() {
    const { taskDefinition } = this.props;
    const createStatus = taskDefinition.getStatus('create');
    const updateStatus = taskDefinition.getStatus('update');
    const deleteStatus = taskDefinition.getStatus('delete');
    const listStatus = taskDefinition.getStatus('list');
    const { editItem } = this.state;

    return (
      <Container>
        <Header>Steps</Header>
        <div className="list">
          <List divided verticalAlign="middle">
            {taskDefinition.steps.map((step) => (
              <List.Item key={step.id}>
                <List.Content floated="right">
                  <Button.Group basic>
                    <Modal
                      title={`Are you sure you want to delete "${truncate(
                        step.question
                      )}"?`}
                      status={deleteStatus}
                      trigger={<Button icon="delete" title="Delete" />}
                      action={{
                        content: 'Delete',
                        onClick: () => this.handleRemove(step)
                      }}
                    >
                      <p />
                    </Modal>
                    <Button
                      icon="edit"
                      title="Edit"
                      onClick={() => this.setState({ editItem: step })}
                    />
                  </Button.Group>
                </List.Content>
                <List.Content>
                  <List.Header>
                    {truncate(step.question, { length: 130 })}
                  </List.Header>
                  <List.Description>
                    {getStepTypeLabel(step.type)} - {step.namespace}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
            {!taskDefinition.steps.length && (
              <List.Item>
                <List.Content>
                  <List.Header>No Steps yet</List.Header>
                </List.Content>
              </List.Item>
            )}
          </List>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
        <Modal
          ref={this.createDialog}
          trigger={
            <Button style={{ marginTop: '20px' }} primary content="Add Step" />
          }
          title="Create Task Definition Step"
          action={{
            content: 'Create',
            primary: true,
            loading: createStatus.request,
            onClick: () =>
              document
                .getElementById('task-create')
                .dispatchEvent(new Event('submit'))
          }}
        >
          <StepForm
            id="task-create"
            onSubmit={this.handleCreate}
            initialValues={{
              type: 'MultipleChoiceSingleResponseStep',
              textChoices: [''],
              buttonText: 'Next'
            }}
          />
        </Modal>
        {editItem && (
          <Modal
            open
            onClose={() => this.setState({ editItem: false })}
            ref={this.editDialog}
            title={`Edit "${truncate(editItem.question)}"`}
            action={{
              primary: true,
              loading: updateStatus.request,
              content: 'Save',
              onClick: () =>
                document
                  .getElementById('task-edit')
                  .dispatchEvent(new Event('submit'))
            }}
          >
            <StepForm
              id="task-edit"
              isUpdate
              onSubmit={this.handleUpdate}
              initialValues={{
                ...editItem
              }}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
