import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import AppWrapper from 'components/AppWrapper';
import InviteForm from './InviteForm';
import { Form } from 'react-final-form';
import EditRolesForm from './EditRolesForm';
import ModalComponent from 'components/Modal';
import { renderUserType } from 'utils/rbac';

import {
  Container,
  Divider,
  Header,
  Table,
  Button,
  Modal,
  Dropdown
} from 'semantic-ui-react';

@inject('appSession', 'roles', 'me')
@observer
export default class Home extends React.Component {
  state = {
    showInviteDialog: false,
    editRolesMember: false
  };

  constructor(props) {
    super(props);
    this.disposeReaction = reaction(
      () => this.props.appSession.organization,
      () => {
        const { organization } = this.props.appSession;
        organization.fetchMembers('members');
      }
    );
    this.editRolesDialog = React.createRef();
  }

  componentDidMount() {
    const { organization } = this.props.appSession;
    organization.fetchMembers('members');
    this.props.roles.list();
  }

  handleCloseDialog = () => {
    this.setState({
      showInviteDialog: false
    });
  };

  handleOpenDialog = () => {
    this.setState({
      showInviteDialog: true
    });
  };

  handleCloseEditRolesDialog = () => {
    this.setState({
      editRolesMember: false
    });
  };

  handleOpenEditRolesDialog = (member) => {
    this.setState({
      editRolesMember: member
    });
  };

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnInvite = () => {
    const { organization } = this.props.appSession;
    organization.fetchMembers('members');
  };

  handleRemoveMember = (member) => {
    const { organization } = this.props.appSession;
    organization.removeMembers([member.id]).then(() => {
      //todo error handling
    });
  };

  handleResentInvite = (member) => {
    const { organization } = this.props.appSession;
    organization.invite([member.email], member.role).then(() => {
      //todo error handling
    });
  };

  handleToggleRole = (member) => {
    const { organization } = this.props.appSession;
    organization
      .setRoles([member.id], member.role === 'admin' ? 'user' : 'admin')
      .then(() => {
        //todo error handling
      });
  };

  handleToggleRole = (member) => {
    const { organization } = this.props.appSession;
    organization
      .setRoles([member.id], member.role === 'admin' ? 'user' : 'admin')
      .then(() => {
        //todo error handling
      });
  };

  getActionsForMember(member, roles) {
    const actions = [
      {
        index: 1,
        key: 'role',
        icon: 'exchange',
        text: `Make ${member.role === 'admin' ? 'user' : 'Administrator'}`,
        value: 'role',
        onClick: () => this.handleToggleRole(member)
      },
      {
        index: 2,
        key: 'delete',
        icon: 'delete',
        text: 'Remove',
        value: 'delete',
        onClick: () => this.handleRemoveMember(member)
      },
      {
        index: 3,
        key: 'edit-roles',
        icon: 'setting',
        text: 'Configure Roles',
        value: 'edit-roles',
        onClick: () => this.handleOpenEditRolesDialog(member),
        disabled: !roles.items.length
      }
    ];

    if (member.type === 'invite') {
      actions.push({
        index: 0,
        key: 'mail',
        icon: 'mail',
        text: 'Re-send invite',
        value: 'resent',
        onClick: () => this.handleResentInvite(member)
      });
    }
    return actions.sort((a, b) => a.index - b.index);
  }

  render() {
    const { me, roles } = this.props;
    const { organization } = this.props.appSession;
    const { editRolesMember } = this.state;

    const hasInviteBtn =
      me.user.role === 'admin' ||
      organization.members.find(
        (m) => m.role === 'admin' && m.id === me.user.id
      );

    const roleListStatus = roles.getStatus('list');
    const updateRolesStatus = roles.getStatus('updateRoles');

    return (
      <AppWrapper>
        <Container style={{ marginTop: '50px' }}>
          <Header as="h2" className="page-title">
            Team Access{' '}
          </Header>
          <Divider hidden />
          <div className="list">
            <Header as="h3">Members </Header>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {!organization.members.length && (
                  <Table.Row>
                    <Table.Cell>No Members yet</Table.Cell>
                  </Table.Row>
                )}
                {organization.members.map((member) => {
                  return (
                    <Table.Row key={member.id}>
                      <Table.Cell>
                        <Header as="h4">
                          <Header.Content>
                            {member.name || '-'}
                            <Header.Subheader>{member.email}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>{renderUserType(member)}</Table.Cell>
                      <Table.Cell>
                        {member.type === 'invite' ? 'invited' : 'accepted'}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          disabled={!hasInviteBtn}
                          text="Actions"
                          options={this.getActionsForMember(member, roles)}
                          button
                          floating
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          {hasInviteBtn && (
            <Modal
              open={this.state.showInviteDialog}
              onClose={this.handleCloseDialog}
              closeOnEscape
              size="tiny"
              trigger={
                <Button
                  style={{ marginTop: '20px' }}
                  onClick={this.handleOpenDialog}
                  primary
                  content="Invite Member"
                />
              }
            >
              <InviteForm
                roles={roles}
                onClose={this.handleCloseDialog}
                onSubmit={this.handleOnInvite}
              />
            </Modal>
          )}
          {editRolesMember && roles.items.length && (
            <ModalComponent
              open
              onClose={() => this.setState({ editRolesMember: false })}
              ref={this.editRolesDialog}
              title={`Edit "${editRolesMember.email}" Roles`}
              action={{
                primary: true,
                loading: updateRolesStatus.request,
                content: 'Close',
                onClick: () => this.setState({ editRolesMember: false })
              }}
            >
              <EditRolesForm
                id="role-edit"
                handleSubmit={this.handleUpdateRolesForMember}
                userId={editRolesMember.id}
                roles={roles}
              />
            </ModalComponent>
          )}
        </Container>
      </AppWrapper>
    );
  }
}
