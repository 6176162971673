import React from 'react';
import { VictoryPie, VictoryLegend } from 'victory';
import { colorScale } from 'utils/analytics';
import { Message } from 'semantic-ui-react';

export default class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWidth: 0
    };
    this.container = React.createRef();
  }

  componentDidMount() {
    this.setState({
      chartWidth: this.container.current.offsetWidth
    });
  }

  render() {
    const { x = 'x', y = 'y', data = [] } = this.props;
    const total = data.reduce(
      (accumulator, currentValue) => accumulator + currentValue[y],
      0
    );

    const legendData = data.sort((a, b) => b.count - a.count).map((c) => {
      return { name: ` ${Math.round((c[y] * 100) / total)}% ${c[x]}` };
    });
    const height = this.props.height || 200;
    const chartWidth = this.state.chartWidth;

    return (
      <div ref={this.container} style={{ height }}>
        {data.length === 0 && (
          <Message
            size="big"
            style={{
              top: '50%',
              position: 'absolute',
              left: 0,
              right: 0,
              margin: 'auto',
              width: '350px',
              textAlign: 'center',
              background: 'rgba(241, 241, 241, 1)',
              border: 'none',
              boxShadow: 'none'
            }}
            content={'No data for this time range'}
          />
        )}

        <svg preserveAspectRatio="none" width={chartWidth} height={height}>
          <g transform={`translate(0,0)`}>
            <VictoryLegend
              key="legend"
              colorScale={colorScale}
              standalone={false}
              x={0}
              y={0}
              centerTitle
              style={{
                border: {},
                labels: { fontSize: 13, fontFamily: 'Lato, sans-serif' }
              }}
              data={legendData}
            />
          </g>
          <VictoryPie
            colorScale={colorScale}
            width={chartWidth}
            height={height}
            innerRadius={height * 0.25}
            standalone={false}
            padding={{
              top: 0
            }}
            x={x}
            y={y}
            data={
              data.length
                ? data
                : [{ x: '1', y: 0 }, { x: '2', y: 0 }, { x: 'No Data', y: 100 }]
            }
            labels={[]}
          />
        </svg>
      </div>
    );
  }
}
