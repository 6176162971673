import BaseStore from 'stores/BaseStore';

export default class UserStore extends BaseStore {
  constructor(props) {
    super(props);
    Object.assign(this, props);
  }

  update(props) {
    Object.assign(this, props);
  }
}
