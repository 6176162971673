export const stepTypes = [
  {
    label: 'Multiple Choice',
    id: 'MultipleChoiceSingleResponseStep'
  },
  {
    label: 'Wong Baker',
    id: 'WongBakerChoiceStep'
  },
  /*
  {
    text: 'Image',
    id: 'ImageChoiceStep'
  },
  */
  {
    label: 'Slider',
    id: 'SliderStep'
  }
];

export function getStepTypeLabel(type) {
  const stepType = stepTypes.find((t) => t.id === type);
  if (!stepType) return 'Unknown Type';
  return stepType.label;
}
