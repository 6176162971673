import React from 'react';
import { observer, inject } from 'mobx-react';

import Modal from 'components/Modal';
import TaskDefinitionForm from 'components/forms/TaskDefinition';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';

import {
  Container,
  Header,
  Loader,
  Segment,
  Dimmer,
  Message,
  Button
} from 'semantic-ui-react';

import Steps from './Steps';

@inject('taskDefinitions')
@observer
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    const { match, taskDefinitions } = props;
    const itemId = match.params.id;
    this.state = {
      statusId: `get-${itemId}`,
      itemId
    };
    taskDefinitions.get(itemId, this.state.statusId);
    this.editDialog = React.createRef();
  }

  handleUpdate = (body) => {
    const { taskDefinitions } = this.props;
    return taskDefinitions
      .update({
        ...body,
        schedulingDayOffsets: body.schedulingDayOffsets
          .split(',')
          .map((c) => parseInt(c.trim(), 10))
          .filter(Boolean)
      })
      .then((err) => {
        if (err instanceof Error) return;
        const dialog = this.editDialog.current;
        if (dialog) dialog.close();
      });
  };

  render() {
    const { taskDefinitions } = this.props;
    const fetchStatus = taskDefinitions.getStatus(this.state.statusId);
    const item = taskDefinitions.getItem(this.state.itemId);

    return (
      <Container>
        <Header as="h2">
          <Link to="/task-definitions">Task Definitions</Link>:{' '}
          {item ? item.data.title : '-'}
          {item && (
            <Modal
              ref={this.editDialog}
              title={`Edit "${item.data.title}"`}
              trigger={
                <Button primary floated="right">
                  Edit
                </Button>
              }
              action={{
                content: 'Update',
                primary: true,
                onClick: () =>
                  document
                    .getElementById('task-edit')
                    .dispatchEvent(new Event('submit'))
              }}
            >
              <TaskDefinitionForm
                id="task-edit"
                isUpdate={true}
                onSubmit={this.handleUpdate}
                initialValues={toJS(item.data)}
              />
            </Modal>
          )}
        </Header>
        {fetchStatus.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        <div>
          {fetchStatus.error && (
            <Message error content={fetchStatus.error.message} />
          )}
          {item && <Steps taskDefinition={item} />}
        </div>
      </Container>
    );
  }
}
