import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';

import { Container, Header, Button } from 'semantic-ui-react';
import ImportWizard from './ImportWizard';

@inject('studies', 'appSession')
@observer
export default class Imports extends React.Component {
  constructor(props) {
    super(props);
    this.wizard = React.createRef();
    const { appSession, studies } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        studies.list();
      }
    );
  }

  componentDidMount() {
    this.props.studies.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    const { studies } = this.props;
    return (
      <Container>
        <Header as="h2">Data Imports</Header>
        <p>
          Here we can import wearable and survey data into the system. Please
          note that all data needs to be shaped correctly in JSON. Once data is
          imported, it cannot be removed without manual engineering
          intervention.
        </p>
        <ImportWizard
          ref={this.wizard}
          onDone={() => {
            this.setState({});
          }}
          initialValues={{}}
          studies={studies}
        />
        <Button
          primary
          content="Create Import Job"
          icon="upload"
          onClick={() => {
            this.setState({
              editItem: null
            });
            this.wizard.current.open();
          }}
        />
      </Container>
    );
  }
}
