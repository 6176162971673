import React, { Component } from 'react';
import { Statistic, Divider } from 'semantic-ui-react';
import SeriesChart from 'components/charts/Series';
import MetricsContainer from 'components/containers/Metrics';
import StackedBarChart from 'components/charts/StackedBar';
import NumberWidget from 'components/NumberWidget';
import { startCase } from 'lodash';

function processStacked(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.measurementType.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });

  const x = Object.keys(namespaces).map((key) => {
    return {
      id: key,
      series: data.map((bucket) => {
        const namespace = bucket.measurementType.find(
          (entity) => entity.id == key
        );
        return {
          x: bucket.ts,
          y: namespace ? namespace.count : 0
        };
      })
    };
  });
  return x;
}

export default class Wearables extends Component {
  render() {
    const { participant } = this.props;
    const { device } = participant;
    return (
      <div>
        <p>
          This screen shows a summary of the collected data for a participant.
          The data shown here is not intended for statistical analysis purposes.
          Please use the raw data exports to get the full resolution of all data
          collected.{' '}
        </p>
        <Divider hidden />
        <Divider hidden />
        <Statistic.Group
          size="large"
          style={{ justifyContent: 'space-between' }}
        >
          {device.type === 'garmin' ? (
            <MetricsContainer
              filterType="analytics"
              noContainer
              type="wearable"
              query={{
                sourceType: 'garmin'
              }}
              interval={false}
            >
              <NumberWidget title="Synced Events" field="count" />
            </MetricsContainer>
          ) : (
            <MetricsContainer
              filterType="analytics"
              noContainer
              type="wearable"
              query={{
                measurementType: 'steps'
              }}
              interval={false}
            >
              <NumberWidget title="Synced Days" field="count" />
            </MetricsContainer>
          )}
          {device.type === 'garmin' ? (
            <MetricsContainer
              filterType="analytics"
              noContainer
              type="wearable"
              query={{
                measurementType: 'steps'
              }}
              metricField="stepCount"
              interval={false}
            >
              <NumberWidget title="Steps" field="count" />
            </MetricsContainer>
          ) : (
            <MetricsContainer
              filterType="analytics"
              noContainer
              type="wearable"
              query={{
                measurementType: 'steps'
              }}
              metricField="totalSteps"
              interval={false}
            >
              <NumberWidget title="Steps" field="count" />
            </MetricsContainer>
          )}
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="wearable"
            query={{
              measurementType: 'sleep'
            }}
            metricField="totalMinutesAsleep"
            interval={false}
          >
            <NumberWidget title="Minutes Asleep" field="count" />
          </MetricsContainer>
        </Statistic.Group>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <MetricsContainer
          filterType="analytics"
          style={{ marginBottom: '20px' }}
          title={`Wearable Sync Status (${startCase(device.type)})`}
          type="wearable"
          entities={['measurementType']}
          processData={processStacked}
        >
          <StackedBarChart />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        {device.type === 'garmin' ? (
          <MetricsContainer
            filterType="analytics"
            type="wearable"
            query={{
              measurementType: 'steps'
            }}
            metricField="stepCount"
            title="Total Steps"
          >
            <SeriesChart x="ts" y="count" type="bar" />
          </MetricsContainer>
        ) : (
          <MetricsContainer
            filterType="analytics"
            type="wearable"
            query={{
              measurementType: 'steps'
            }}
            metricField="totalSteps"
            title="Total Steps"
          >
            <SeriesChart x="ts" y="count" type="bar" />
          </MetricsContainer>
        )}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {device.type === 'garmin' ? (
          <MetricsContainer
            filterType="analytics"
            type="wearable"
            query={{
              measurementType: 'heartRate'
            }}
            metricField="heartRate"
            metricOperation="avg"
            title="Heart Rate"
          >
            <SeriesChart x="ts" y="count" type="bar" />
          </MetricsContainer>
        ) : (
          <MetricsContainer
            filterType="analytics"
            type="wearable"
            query={{
              measurementType: 'heart'
            }}
            metricField="value.restingHeartRate"
            metricOperation="avg"
            title="Daily Resting Heart Rate"
          >
            <SeriesChart x="ts" y="count" type="bar" />
          </MetricsContainer>
        )}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {device.type !== 'garmin' && (
          <>
            <MetricsContainer
              filterType="analytics"
              type="wearable"
              query={{
                measurementType: 'sleep'
              }}
              metricField="totalMinutesAsleep"
              title="Total Minutes Asleep"
            >
              <SeriesChart x="ts" y="count" type="bar" />
            </MetricsContainer>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
          </>
        )}
      </div>
    );
  }
}
