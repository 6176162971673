import React from 'react';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import logoInverted from 'assets/logo_inverted.svg';
import FilterLink from 'components/Link';
import Footer from 'components/Footer';
import styled from 'styled-components';
import { hasPermissions, isSuperAdmin } from 'utils/rbac';

import { isLegacyOrganization } from 'utils/legacy';

const TopMenu = styled(Menu)`
  &.ui.inverted.menu {
    background: #060439;
    padding: 25px 0 25px 0;
  }

  &.ui.inverted.menu .item {
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-left: 2.2em;
    padding-right: 2.2em;

    &::before {
      background: none;
    }
  }

  &.ui.inverted.menu .item:first-child {
    padding-left: 0;
    padding-right: 1.5em;
  }

  &.ui.inverted.menu .active.item,
  &.ui.inverted.menu .item:hover {
    background: none;
  }

  &.ui.inverted.menu .right.menu .item:first-child::before {
    background: none;
  }

  &.ui.inverted.menu .ui.button.organizationsDropdown {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;

    input.search {
      color: #fff;
    }
  }
`;

@inject('me', 'organizations', 'appSession')
@withRouter
@observer
export default class TopNav extends React.Component {
  render() {
    const path = this.props.location.pathname;
    const { organizations, appSession, me } = this.props;

    return (
      <div style={{ overflow: 'hidden' }}>
        <TopMenu inverted fixed="top">
          <Container>
            <Menu.Item as={Link} to="/">
              <img
                style={{ width: '110px' }}
                alt="Litmus Health"
                className="logo"
                src={`${logoInverted}`}
              />
            </Menu.Item>
            {organizations.items.length && (
              <Menu.Item
                active={['/', '/participants'].includes(path)}
                as={FilterLink}
                to="/participants"
              >
                Participants
              </Menu.Item>
            )}
            {organizations.items.length && (
              <Menu.Item as={FilterLink} to="/analytics">
                Analytics
              </Menu.Item>
            )}
            <Menu.Menu position="right">
              <Menu.Item>
                <Dropdown
                  compact
                  onChange={(e, { value }) =>
                    appSession.setOrganization(
                      organizations.items.find((item) => item.id === value)
                    )
                  }
                  style={{ marginLeft: '10px', width: '200px' }}
                  value={appSession.organization && appSession.organization.id}
                  button
                  selection
                  fluid
                  className="organizationsDropdown"
                  options={organizations.items.map((organization) => {
                    return {
                      // key: organization.id,
                      text: organization.name,
                      value: organization.id
                    };
                  })}
                />
              </Menu.Item>
              <Dropdown
                className="account"
                item
                trigger={<span>{this.props.me.user.name}</span>}
              >
                <Dropdown.Menu>
                  {isSuperAdmin(me.user) && (
                    <Dropdown.Item as={Link} to="/organizations">
                      Organizations
                    </Dropdown.Item>
                  )}

                  {hasPermissions(me.user, 'studies', 'read') && (
                    <Dropdown.Item as={Link} to="/studies">
                      Studies
                    </Dropdown.Item>
                  )}

                  {!isLegacyOrganization(appSession.organization) &&
                    hasPermissions(me.user, 'enrollments', 'read') && (
                      <Dropdown.Item as={Link} to="/enrollments">
                        Enrollments
                      </Dropdown.Item>
                    )}
                  {hasPermissions(me.user, 'taskDefinitions', 'read') && (
                    <Dropdown.Item as={Link} to="/task-definitions">
                      Task Definitions
                    </Dropdown.Item>
                  )}
                  {hasPermissions(me.user, 'devices', 'read') && (
                    <Dropdown.Item as={Link} to="/devices">
                      Devices
                    </Dropdown.Item>
                  )}
                  {hasPermissions(me.user, 'surveyMetrics', 'read') && (
                    <Dropdown.Item as={Link} to="/exports">
                      Data Exports
                    </Dropdown.Item>
                  )}
                  {isSuperAdmin(me.user) && (
                    <Dropdown.Item as={Link} to="/imports">
                      Data Imports
                    </Dropdown.Item>
                  )}
                  {hasPermissions(me.user, 'users', 'read') && (
                    <Dropdown.Item as={Link} to="/team">
                      Team Access
                    </Dropdown.Item>
                  )}
                  {isSuperAdmin(me.user) && (
                    <Dropdown.Item as={Link} to="/users">
                      Users
                    </Dropdown.Item>
                  )}

                  {hasPermissions(me.user, 'roles', 'read') && (
                    <Dropdown.Item as={Link} to="/roles">
                      Roles
                    </Dropdown.Item>
                  )}

                  {hasPermissions(me.user, 'mutations', 'read') && (
                    <Dropdown.Item as={Link} to="/mutations">
                      Audit Trail
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item as={Link} to="/logout">
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Container>
        </TopMenu>
        <Container style={{ marginTop: '160px', minHeight: '500px' }}>
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
