import React from 'react';
import { Checkbox, Form, Loader, Message } from 'semantic-ui-react';
import appSession from 'stores/AppSession';
import request from 'utils/request';

export default class StudyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      checked: false
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const { role, userId } = this.props;
    request({
      method: 'GET',
      path: `/1/roles/${appSession.organization.id}/${role.id}/members`
    })
      .then(({ data }) => {
        const checked = data.map((item) => item.id).includes(userId);
        this.setState({ error: null, loading: false, checked });
      })
      .catch((error) => {
        this.setState({ error, loading: true });
      });
  }

  toggle() {
    const { role, userId } = this.props;
    const { checked } = this.state;
    this.setState({ loading: true });
    if (!checked) {
      request({
        method: 'POST',
        path: `/1/roles/${appSession.organization.id}/${role.id}/${userId}`
      })
        .then(({ data }) => {
          this.fetch();
        })
        .catch((error) => {
          this.setState({ error, loading: true });
        });
    } else {
      request({
        method: 'DELETE',
        path: `/1/roles/${appSession.organization.id}/${role.id}/${userId}`
      })
        .then(({ data }) => {
          this.fetch();
        })
        .catch((error) => {
          this.setState({ error, loading: true });
        });
    }
  }

  render() {
    const { role } = this.props;
    const { error, loading, checked } = this.state;
    if (loading) return <Loader active inline />;
    if (error) return <Message error content={error.message} />;
    return (
      <Form.Field
        control={Checkbox}
        label={role.name}
        checked={checked}
        onClick={(e) => {
          this.toggle();
        }}
      />
    );
  }
}
