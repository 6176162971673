import React from 'react';
import { observer, inject } from 'mobx-react';
import TaskDefinitionForm from 'components/forms/TaskDefinition';
import moment from 'moment';
import Modal from 'components/Modal';
import { Link } from 'react-router-dom';

import {
  Container,
  Header,
  Table,
  Button,
  Loader,
  Segment,
  Dimmer,
  Message
} from 'semantic-ui-react';

@inject('taskDefinitions', 'appSession', 'me')
@observer
export default class Home extends React.Component {
  state = {
    showCreateDialog: false
  };

  constructor(props) {
    super(props);
    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
    this.props.taskDefinitions.list({
      studyId: this.props.studyId
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.studyId && this.props.studyId !== prevProps.studyId) {
      this.props.taskDefinitions.list({
        studyId: this.props.studyId
      });
    }
  }

  handleRemove = (taskDefinition) => {
    const { taskDefinitions } = this.props;
    taskDefinitions.delete(taskDefinition);
  };

  handleCreate = ({ schedulingDayOffsets, ...body }) => {
    const { taskDefinitions, studyId } = this.props;
    return taskDefinitions
      .create({
        ...body,
        schedulingDayOffsets: schedulingDayOffsets
          .split(',')
          .map((c) => parseInt(c.trim(), 10))
          .filter(Boolean),
        studyId
      })
      .then((result) => {
        if (result instanceof Error) return;
        const dialog = this.createDialog.current;
        if (dialog) dialog.close();
      });
  };

  handleUpdate = (body) => {
    const { taskDefinitions } = this.props;
    return taskDefinitions
      .update({
        ...body,
        schedulingDayOffsets: body.schedulingDayOffsets
          .split(',')
          .map((c) => parseInt(c.trim(), 10))
          .filter(Boolean)
      })
      .then((err) => {
        if (err instanceof Error) return;
        const dialog = this.editDialog.current;
        if (dialog) dialog.close();
      });
  };

  render() {
    const { taskDefinitions, me } = this.props;
    const createStatus = taskDefinitions.getStatus('create');
    const updateStatus = taskDefinitions.getStatus('update');
    const deleteStatus = taskDefinitions.getStatus('delete');
    const listStatus = taskDefinitions.getStatus('list');
    const { editItem } = this.state;

    const { organization } = this.props.appSession;

    const isOrganizationAdmin =
      me.user.role === 'admin' ||
      organization.members.find(
        (m) => m.role === 'admin' && m.id === me.user.id
      );

    return (
      <Container>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell width={3}>Number of Steps</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created At</Table.HeaderCell>
                <Table.HeaderCell width={4} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!taskDefinitions.items.length && (
                <Table.Row>
                  <Table.Cell>No Task Definitions yet</Table.Cell>
                </Table.Row>
              )}
              {taskDefinitions.items.map((item) => {
                const { data } = item;
                return (
                  <Table.Row key={data.id}>
                    <Table.Cell>
                      <Header as="h4">
                        <Header.Content>{data.title}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell collapsing>{data.stepCount}</Table.Cell>
                    <Table.Cell collapsing>
                      {moment(data.createdAt).format('LL')}
                    </Table.Cell>
                    <Table.Cell collapsing textAlign="center">
                      {isOrganizationAdmin && (
                        <Button.Group basic>
                          <Modal
                            title={`Are you sure you want to delete "${
                              data.title
                            }"?`}
                            status={deleteStatus}
                            trigger={<Button icon="delete" title="Delete" />}
                            action={{
                              content: 'Delete',
                              onClick: () => this.handleRemove(item)
                            }}
                          >
                            <p />
                            <ul>
                              <li>All Steps will be deleted.</li>
                            </ul>
                          </Modal>
                          <Button
                            icon="edit"
                            title="Edit"
                            onClick={() => {
                              this.setState({ editItem: item });
                            }}
                          />
                          <Button as={Link} to={`/task-definitions/${data.id}`}>
                            View Steps
                          </Button>
                        </Button.Group>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
        {isOrganizationAdmin && (
          <Modal
            ref={this.createDialog}
            trigger={
              <Button
                style={{ marginTop: '20px' }}
                primary
                content="Create new Task Definition"
              />
            }
            title="Create Task Definition"
            action={{
              content: 'Create',
              primary: true,
              loading: createStatus.request,
              onClick: () =>
                document
                  .getElementById('task-create')
                  .dispatchEvent(new Event('submit'))
            }}
          >
            <TaskDefinitionForm
              id="task-create"
              onSubmit={this.handleCreate}
              initialValues={{ type: 'SingleStepTask' }}
            />
          </Modal>
        )}
        {editItem && (
          <Modal
            open
            onClose={() => this.setState({ editItem: false })}
            ref={this.editDialog}
            title={`Edit "${editItem.data.title}"`}
            action={{
              primary: true,
              loading: updateStatus.request,
              content: 'Save',
              onClick: () =>
                document
                  .getElementById('task-edit')
                  .dispatchEvent(new Event('submit'))
            }}
          >
            <TaskDefinitionForm
              id="task-edit"
              isUpdate={true}
              onSubmit={this.handleUpdate}
              initialValues={{ ...editItem.data }}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
