import React from 'react';
import {
  Message,
  Table,
  Button,
  Label,
  Divider,
  Pagination,
  Loader
} from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import { numberWithCommas } from 'utils/formatting';
import styled from 'styled-components';

import HelpTip from 'components/HelpTip';

const StyledTable = styled(Table)`
  .unsortable.unsortable {
    cursor: default !important;

    &:hover {
      background: #f9fafb !important;
    }
  }
`;

export default class ParticipantsTable extends React.Component {
  handleOnPageChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.props.onPageChange(activePage);
  };

  renderTable() {
    const {
      data,
      disablePagination,
      disableFiltering,
      meta,
      status,
      sort,
      page,
      disableSurveys = false
    } = this.props;

    if (!data || !data.length) {
      if (status.request) {
        return <Loader active />;
      } else {
        return <Message content="No participants added yet" />;
      }
    }

    const sortOrder = sort.order === 'asc' ? 'ascending' : 'descending';

    return (
      <div>
        <Divider hidden />
        <StyledTable celled sortable={!disableFiltering} selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                onClick={() => this.props.onSortChanged('subjectKey')}
                sorted={sort.field === 'subjectKey' ? sortOrder : null}
              >
                Subject ID
              </Table.HeaderCell>
              {false && <Table.HeaderCell>State</Table.HeaderCell>}
              <Table.HeaderCell className="unsortable">
                Activity Classification
                <HelpTip
                  title="Activity Classification"
                  text="Litmus uses 10 different tests to verify the activity status of a subject. If all tests pass, status is set to Normal."
                />
              </Table.HeaderCell>
              {!disableSurveys && (
                <Table.HeaderCell
                  onClick={() =>
                    this.props.onSortChanged('surveySummary.All.to')
                  }
                  sorted={
                    sort.field === 'surveySummary.All.to' ? sortOrder : null
                  }
                >
                  Last Survey
                  <HelpTip
                    title="Last Survey"
                    text="This is the last date any survey has been completed by the subject in the mobile application."
                  />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell
                onClick={() =>
                  this.props.onSortChanged('wearableSummary.steps.to')
                }
                sorted={
                  sort.field === 'wearableSummary.steps.to' ? sortOrder : null
                }
              >
                Last Steps Data
                <HelpTip
                  title="Last Steps"
                  text="This is the last date any non zero Steps data has been pulled from the wearable provider (e.g. Fitbit) in the past 600 days. If no data was found in the past 600 days, last date is not known. Litmus only pulls data once data has been syncronized from the device."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => this.props.onSortChanged('createdAt')}
                sorted={sort.field === 'createdAt' ? sortOrder : null}
              >
                Enrolled
                <HelpTip
                  title="Enrolled"
                  text="Date that the subject joined the study in the mobile application."
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item) => {
              const activityStatuses = item.activityStatuses || [];
              return (
                <Table.Row
                  key={item._id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.onSelect(item.subjectKey)}
                >
                  <Table.Cell width={1}>{item.subjectKey}</Table.Cell>
                  {false && (
                    <Table.Cell width={2}>
                      {item.isActive ? (
                        <Label color="blue" content="Active" />
                      ) : (
                        <Label color="white" content="Inactive" />
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell width={5}>
                    {activityStatuses.length > 0 ? (
                      activityStatuses.map((activityStatus) => {
                        return (
                          <Label
                            size="tiny"
                            key={activityStatus.id}
                            color={activityStatus.color}
                            content={activityStatus.name}
                            icon={activityStatus.icon}
                            title={activityStatus.reason}
                            style={{ marginBottom: '3px' }}
                          />
                        );
                      })
                    ) : (
                      <i>No anomalies detected</i>
                    )}
                    {activityStatuses.length > 0 && (
                      <HelpTip
                        title={`Activity Classifications for ${item.subjectKey}`}
                        icon="list"
                        text={
                          <Table inverted>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width={4}>
                                  Status
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                  Type
                                </Table.HeaderCell>
                                <Table.HeaderCell width={8}>
                                  Reason
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {activityStatuses.map((activityStatus) => {
                                return (
                                  <Table.Row key={activityStatus.id}>
                                    <Table.Cell>
                                      {activityStatus.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {activityStatus.typeName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {activityStatus.reason}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            </Table.Body>
                          </Table>
                        }
                      />
                    )}
                  </Table.Cell>
                  {!disableSurveys && (
                    <Table.Cell width={2}>
                      {item.surveySummary && item.surveySummary.All.to ? (
                        <TimeAgo
                          date={Math.floor(item.surveySummary.All.to) * 1000}
                        />
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell width={2}>
                    {item.wearableSummary &&
                    item.wearableSummary.steps &&
                    item.wearableSummary.steps.to ? (
                      <TimeAgo
                        date={Math.floor(item.wearableSummary.steps.to * 1000)}
                      />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <TimeAgo
                      date={Date.parse(item.createdAt || item.joinedAt)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {!disablePagination && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="7" textAlign="center">
                  <Pagination
                    floated="right"
                    activePage={page}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    onPageChange={this.handleOnPageChange}
                    totalPages={Math.ceil(meta.total / meta.limit)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </StyledTable>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderTable()}
        <Divider hidden />
        <Button
          primary
          icon="download"
          content="Export CSV"
          onClick={() => this.props.onExport()}
        />
        <Divider hidden />
      </div>
    );
  }
}
