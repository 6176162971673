import React, { Component } from 'react';

import { Table } from 'semantic-ui-react';

function flatten(obj, prefix, current) {
  prefix = prefix || [];
  current = current || {};

  // Remember kids, null is also an object!
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach((key) => {
      flatten(obj[key], prefix.concat(key), current);
    });
  } else {
    current[prefix.join(' / ')] = obj;
  }

  return current;
}

export default class Home extends Component {
  render() {
    const { participant } = this.props;
    const deviceInfo = participant.deviceInfo || {};

    return (
      <div>
        <p>
          This device information is from the Litmus Study Companion app only.
        </p>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Device Id</Table.Cell>
              <Table.Cell>{deviceInfo.deviceId || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Device Name</Table.Cell>
              <Table.Cell>{deviceInfo.deviceName || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Device Year Class</Table.Cell>
              <Table.Cell>{deviceInfo.deviceYearClass || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Expo Runtime Version</Table.Cell>
              <Table.Cell>{deviceInfo.expoRuntimeVersion || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Expo Version</Table.Cell>
              <Table.Cell>{deviceInfo.expoVersion || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Installation Id</Table.Cell>
              <Table.Cell>{deviceInfo.installationId || '-'}</Table.Cell>
            </Table.Row>
            {deviceInfo.manifest &&
              Object.keys(flatten(deviceInfo.manifest)).map((key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell>Manifest / {key}</Table.Cell>
                    <Table.Cell>{deviceInfo.manifest[key]}</Table.Cell>
                  </Table.Row>
                );
              })}
            {deviceInfo.platform &&
              Object.keys(flatten(deviceInfo.platform)).map((key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell>Platform / {key}</Table.Cell>
                    <Table.Cell>{deviceInfo.platform[key]}</Table.Cell>
                  </Table.Row>
                );
              })}
            <Table.Row>
              <Table.Cell>Time Zone</Table.Cell>
              <Table.Cell>{participant.timeZone || '-'}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
