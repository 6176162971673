import styled from 'styled-components';

import { Menu } from 'semantic-ui-react';

export default styled(Menu)`
  &.ui.secondary.secondary {
    border-bottom: 4px solid #e6e6e6;
  }

  .menu.left .item {
    font-size: 24px;
    color: #13009b;
    font-weight: bold;
    padding: 0.6em 0 !important;
    margin: 0em 2em -4px 0em !important;
  }

  .menu.left .item.item {
    border-bottom-width: 4px;
    border-color: transparent;
  }

  .menu.left .active.item.item,
  .menu.left .active.item.item:hover {
    color: #13009b;
    border-color: #00ffb0;
  }
`;
