import React from 'react';
import { Popup, Input, Button, Form, Radio, Label } from 'semantic-ui-react';

import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';

import styled from 'styled-components';
import 'react-day-picker/lib/style.css';

export const timeOptions = [
  { value: 'custom', text: 'Custom Range', timeRange: undefined },
  { value: 'all', text: 'All Time', timeRange: undefined },
  {
    value: '7days',
    text: 'Last 7 Days',
    timeRange: {
      from: moment()
        .subtract(7, 'day')
        .startOf('day'),
      to: moment().endOf('day')
    }
  },
  {
    value: '30days',
    text: 'Last 30 Days',
    timeRange: {
      from: moment()
        .subtract(30, 'days')
        .startOf('day'),
      to: moment().endOf('day')
    }
  },
  {
    value: '90days',
    text: 'Last 90 Days',
    timeRange: {
      from: moment()
        .subtract(90, 'days')
        .startOf('day'),
      to: moment().endOf('day')
    }
  }
  /*
  {
    value: 'year',
    text: 'This year',
    timeRange: {
      from: moment().startOf('year'),
      to: moment().endOf('year')
    }
  }
  */
];

const Container = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;

  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #13009b !important;
    color: #4a90e2;
  }

  .DayPicker {
    margin-right: 10px;
    user-select: none;
  }

  .DayPicker *:focus {
    outline: none;
  }

  .DayPicker-NavButton {
    top: 0.2em;
    right: 0.4em;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker-Month {
    margin-top: 0px;
    margin-right: 0px;
  }

  .DayPicker-Month:first-child {
    margin: 0px;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .controls {
    width: 200px;
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`;

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      isOpen: false,
      timeRangeType: this.guessTimeRangeType(props.timeRange),
      from: undefined,
      to: undefined
    };
  }

  componentDidUpdate(props) {
    if (props.timeRange !== this.props.timeRange) {
      this.setState({
        timeRangeType: this.guessTimeRangeType(this.props.timeRange)
      });
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  guessTimeRangeType(timeRange) {
    if (!timeRange) {
      return 'all';
    }

    const selected = timeOptions.find((option) => {
      const optionTimeRange = option.timeRange;
      if (!optionTimeRange) return false;
      return (
        optionTimeRange.from.isSame(timeRange.from) &&
        optionTimeRange.to.isSame(timeRange.to)
      );
    });

    if (selected) return selected.value;
    return 'custom';
  }

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState({ ...range, timeRangeType: 'custom' });
  };

  handleTimeRangeChange = (e, { value }) => {
    const timeRange = timeOptions.find((c) => c.value === value).timeRange;
    const options = { timeRangeType: value };
    if (timeRange) {
      options.from = timeRange.from.toDate();
      options.to = timeRange.to.toDate();
    } else {
      options.from = undefined;
      options.to = undefined;
    }
    this.setState(options);
  };

  handleOnApply = () => {
    const { from, to } = this.state;
    if (from === undefined && to === undefined) {
      this.props.onChange(undefined);
    } else {
      this.props.onChange({ from, to });
    }
    this.setState({ isOpen: false });
  };

  getTimeRangeLabel = () => {
    const { timeRange } = this.props;
    if (!timeRange) {
      return 'All Time';
    }
    const timeRangeType = this.guessTimeRangeType(this.props.timeRange);
    if (timeRangeType !== 'custom') {
      return timeOptions.find((c) => c.value === timeRangeType).text;
    }
    return `${moment(timeOptions.from).format('l')} - ${moment(
      timeOptions.to
    ).format('l')}`;
  };

  render() {
    const { from, to, timeRangeType } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <Popup
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={this.state.isOpen}
        flowing
        on="click"
        trigger={
          <Input
            icon="calendar alternate outline"
            placeholder={`${this.props.label ||
              'Date'}: ${this.getTimeRangeLabel()}`}
          />
        }
        position="bottom right"
      >
        <Container>
          <DayPicker
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
          />
          <div className="controls">
            <Form>
              <h4
                style={{
                  fontWeight: 500,
                  fontSize: '1.15em'
                }}
              >
                Presets
              </h4>
              {timeOptions.map((timeOption) => {
                return (
                  <Form.Field
                    key={timeOption.value}
                    style={{ margin: '0 0 4px 0' }}
                  >
                    <Label
                      style={{
                        display: 'block',
                        margin: 0
                      }}
                    >
                      <Radio
                        label={timeOption.text}
                        name="timeRangeType"
                        value={timeOption.value}
                        checked={timeRangeType === timeOption.value}
                        onChange={this.handleTimeRangeChange}
                      />
                    </Label>
                  </Form.Field>
                );
              })}
            </Form>
            <Button onClick={this.handleOnApply} primary>
              Apply
            </Button>
          </div>
        </Container>
      </Popup>
    );
  }
}
