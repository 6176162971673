import React from 'react';
import { Form } from 'react-final-form';
import { Form as FormEl } from 'semantic-ui-react';

export default class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      formId: 'wizard-form'
    };
  }

  handleNext = (values) => {
    this.setState(
      (state) => ({
        page: Math.min(state.page + 1, this.props.children.length - 1),
        values
      }),
      () => {
        this.props.onNext(
          this.state.page,
          this.state.page === React.Children.count(this.props.children) - 1
        );
      }
    );
  };

  next = () => {
    document
      .getElementById(this.state.formId)
      .dispatchEvent(new Event('submit'));
  };

  previous = () => {
    this.setState(
      (state) => ({
        page: Math.max(state.page - 1, 0)
      }),
      () => {
        this.props.onPrev(this.state.page);
      }
    );
  };

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      this.handleNext(values);
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, errors }) => (
          <FormEl
            error={Object.keys(errors).length > 0}
            id={this.state.formId}
            onSubmit={handleSubmit}
          >
            {activePage}
          </FormEl>
        )}
      </Form>
    );
  }
}
