import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Menu,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Message
} from 'semantic-ui-react';
import Overview from './Overview';
import Surveys from './Surveys';
import Wearables from './Wearables';
import Device from './Device';

import AppWrapper from 'components/AppWrapper';
import Link from 'components/Link';
import SecondaryMenu from 'components/SecondaryMenu';
import Filters from './Filters';
import { observer, inject } from 'mobx-react';
import { isLegacyOrganization } from 'utils/legacy';
import request from 'utils/request';
import moment from 'moment';

@inject('filters', 'appSession')
@observer
export default class Home extends Component {
  constructor(props) {
    super(props);
    const { subjectKey } = props.match.params;
    this.state = {};
    props.filters.analytics.setSubject(subjectKey);
  }

  componentDidMount() {
    const { subjectKey } = this.props.match.params;
    const { organization } = this.props.appSession;

    request({
      method: 'GET',
      path: isLegacyOrganization(this.props.appSession.organization)
        ? `/1/legacy/participants/${subjectKey}?organization=${organization.id}`
        : `/1/analytics/participants/${subjectKey}?organization=${organization.id}`
    })
      .then(({ data }) => {
        const createdAt = data.createdAt || data.joinedAt;
        if (createdAt) {
          this.props.filters.analytics.setDefaultTimeRangeFrom(
            moment(createdAt)
              .subtract(3, 'days')
              .toDate()
          );
        }
        this.setState({
          participant: data
        });
      })
      .catch((e) => {
        this.setState({
          error: e
        });
      });
  }

  componentWillUnmount() {
    this.props.filters.analytics.reset();
  }

  render() {
    const path = this.props.location.pathname;
    const { subjectKey } = this.props.match.params;
    const { appSession } = this.props;

    const disableSurveys =
      appSession.organization && appSession.organization.disableSurveys;

    return (
      <AppWrapper>
        <h2 className="page-title">Participant {subjectKey}</h2>

        <SecondaryMenu pointing secondary>
          <Menu.Menu position="left">
            <Menu.Item
              exact
              active={['/', '/analytics'].includes(path)}
              name="Overview"
              as={Link}
              to={`/participants/${subjectKey}`}
            />
            {!disableSurveys && (
              <Menu.Item
                name="Surveys"
                as={Link}
                to={`/participants/${subjectKey}/surveys`}
              />
            )}
            {!isLegacyOrganization(appSession.organization) && (
              <Menu.Item
                name="Wearables"
                as={Link}
                to={`/participants/${subjectKey}/wearables`}
              />
            )}
            {!disableSurveys && (
              <Menu.Item
                name="User Info"
                as={Link}
                to={`/participants/${subjectKey}/device`}
              />
            )}
          </Menu.Menu>
          <Filters />
        </SecondaryMenu>
        <Divider hidden />
        <Divider hidden />

        {this.state.error && (
          <Message error content={this.state.error.message} />
        )}

        {!this.state.participant && !this.state.error && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {this.state.participant && (
          <Switch>
            <Route
              exact
              path="/participants/:subjectKey/device"
              component={(props) => (
                <Device {...props} participant={this.state.participant} />
              )}
            />
            <Route
              path="/participants/:subjectKey/surveys"
              component={(props) => (
                <Surveys {...props} participant={this.state.participant} />
              )}
            />
            <Route
              path="/participants/:subjectKey/wearables"
              component={(props) => (
                <Wearables {...props} participant={this.state.participant} />
              )}
            />
            <Route
              exact
              path=""
              component={(props) => (
                <Overview
                  {...props}
                  participant={this.state.participant}
                  disableSurveys={disableSurveys}
                />
              )}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}
