import React from 'react';
import LegacyParticipantsTable from './LegacyTable';
import ParticipantsTable from './Table';
import AppWrapper from 'components/AppWrapper';
import EntitiesContainer from 'components/containers/Entities';
import Filters from './Filters';
import { observer, inject } from 'mobx-react';
import { isLegacyOrganization } from 'utils/legacy';
import { hasPermissions } from '../../utils/rbac';
import { Message } from 'semantic-ui-react';

const limit = 25;

@inject('routing', 'appSession', 'filters', 'me')
@observer
export default class Participant extends React.Component {
  state = {
    skip: 0,
    sort: {
      field: 'joinedAt',
      order: 'desc'
    },
    page: 1
  };

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const subject = params.get('subject');
    props.filters.participants.setSubject(subject);
  }

  componentWillUnmount() {
    this.props.filters.participants.reset();
  }

  handleOnSortChange = (field) => {
    const sort = this.state.sort;
    let order = 'desc';
    if (sort.field === field) {
      order = sort.order === 'desc' ? 'asc' : 'desc';
    }
    this.setState({
      page: 1,
      skip: 0,
      sort: {
        field,
        order
      }
    });
  };

  handlePageChange = (page) => {
    this.setState({
      page,
      skip: (page - 1) * limit
    });
  };

  handleClassificationChange = (value) => {
    this.setState({
      query: {
        ...this.state.query,
        classification: value
      }
    });
  };

  handleItemSelected = (subjectId) => {
    this.props.routing.push(`/participants/${subjectId}`);
  };

  handleExport = () => {
    const mapping = {
      'Subject Key': (row) => row.subjectKey,
      'User ID': (row) => row.userId,
      'Study ID': (row) => row.studyId,
      'Join Date': (row) => {
        const date = new Date(Date.parse(row.joinedAt));
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
      'Activity Status': (row) => row.activityStatus.name,
      'Activity Status Reason': (row) => row.activityStatus.reason
    };
    this.entitiesContainer.wrappedInstance.toCsv(mapping, 'participants.csv');
  };

  render() {
    const { skip, query, page } = this.state;
    const { appSession, me } = this.props;

    const disableSurveys =
      appSession.organization && appSession.organization.disableSurveys;

    return (
      <AppWrapper>
        <h2 className="page-title">Participants</h2>
        {hasPermissions(me.user, 'enrollments', 'read') ? (
          <>
            <Filters onClassificationChange={this.handleClassificationChange} />
            <EntitiesContainer
              filterType="participants"
              ref={(r) => (this.entitiesContainer = r)}
              noContainer
              type="participants"
              limit={limit}
              skip={skip}
              query={query}
              sort={this.state.sort}
            >
              {isLegacyOrganization(appSession.organization) ? (
                <LegacyParticipantsTable
                  page={page}
                  sort={this.state.sort}
                  onSortChanged={this.handleOnSortChange}
                  onPageChange={this.handlePageChange}
                  onSelect={this.handleItemSelected}
                  onExport={this.handleExport}
                />
              ) : (
                <ParticipantsTable
                  disableSurveys={disableSurveys}
                  page={page}
                  sort={this.state.sort}
                  onSortChanged={this.handleOnSortChange}
                  onPageChange={this.handlePageChange}
                  onSelect={this.handleItemSelected}
                  onExport={this.handleExport}
                />
              )}
            </EntitiesContainer>
          </>
        ) : (
          <Message content="You have no permissions to view participants" />
        )}
      </AppWrapper>
    );
  }
}
