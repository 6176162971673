import React from 'react';
import { observer, inject } from 'mobx-react';
import OrganizationForm from './OrganizationForm';
import moment from 'moment';
import { Form } from 'react-final-form';
import Modal from 'components/Modal';

import {
  Container,
  Divider,
  Header,
  Table,
  Button,
  Loader,
  Segment,
  Dimmer,
  Message
} from 'semantic-ui-react';

@inject('organizations')
@observer
export default class Home extends React.Component {
  state = {
    showCreateDialog: false
  };
  constructor(props) {
    super(props);
    this.createDialog = React.createRef();
    this.editDialog = React.createRef();
  }

  componentDidMount() {
    this.props.organizations.list();
  }

  handleOnCreate = () => {};

  handleRemove = (organization) => {
    const { organizations } = this.props;
    organizations.delete(organization);
  };

  handleCreate = (body) => {
    const { organizations } = this.props;
    return organizations.create(body).then((result) => {
      if (result instanceof Error) return;
      const dialog = this.createDialog.current;
      if (dialog) dialog.close();
    });
  };

  handleUpdate = (body) => {
    const { organizations } = this.props;
    return organizations.update(body).then((err) => {
      if (err instanceof Error) return alert(`Error: ${err.message}`);
      const dialog = this.editDialog.current;
      if (dialog) dialog.close();
    });
  };

  render() {
    const { organizations } = this.props;
    const createStatus = organizations.getStatus('create');
    const updateStatus = organizations.getStatus('update');
    const deleteStatus = organizations.getStatus('delete');
    const listStatus = organizations.getStatus('list');
    const { editItem } = this.state;

    return (
      <Container>
        <Header as="h2" className="page-title">
          Organizations
        </Header>
        <Divider hidden />
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Members</Table.HeaderCell>
                <Table.HeaderCell width={4}>Created At</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!organizations.items.length && (
                <Table.Row>
                  <Table.Cell>No Organizations yet</Table.Cell>
                </Table.Row>
              )}
              {organizations.items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Header as="h4">
                        <Header.Content>{item.name}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{item.membersCount}</Table.Cell>
                    <Table.Cell>
                      {moment(item.createdAt).format('LL')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {
                        <Button.Group basic>
                          <Modal
                            title={`Are you sure you want to delete "${item.name}"?`}
                            status={deleteStatus}
                            trigger={<Button icon="delete" title="Delete" />}
                            action={{
                              content: 'Delete',
                              onClick: () => this.handleRemove(item)
                            }}
                          >
                            <p />
                            <ul>
                              <li>All data collected will be deleted.</li>
                              <li>
                                All {item.membersCount} members will be removed
                                from the organization.
                              </li>
                            </ul>
                          </Modal>
                          <Button
                            icon="edit"
                            title="Edit"
                            onClick={() => this.setState({ editItem: item })}
                          />
                        </Button.Group>
                      }
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
        <Modal
          ref={this.createDialog}
          trigger={
            <Button
              style={{ marginTop: '20px' }}
              primary
              content="Create New Organization"
            />
          }
          title="Create Organization"
          action={{
            content: 'Create',
            primary: true,
            loading: createStatus.request,
            onClick: () =>
              document
                .getElementById('organization-create')
                .dispatchEvent(new Event('submit'))
          }}
        >
          <Form
            id="organization-create"
            onSubmit={this.handleCreate}
            render={OrganizationForm}
          />
        </Modal>
        {editItem && (
          <Modal
            open
            onClose={() => this.setState({ editItem: false })}
            ref={this.editDialog}
            title={`Edit "${editItem.name}"`}
            action={{
              primary: true,
              loading: updateStatus.request,
              content: 'Save',
              onClick: () =>
                document
                  .getElementById('organization-edit')
                  .dispatchEvent(new Event('submit'))
            }}
          >
            <Form
              id="organization-edit"
              onSubmit={this.handleUpdate}
              render={OrganizationForm}
              initialValues={{
                id: editItem.id,
                name: editItem.name,
                passwordMinLength: editItem.passwordMinLength,
                passwordNeedsNumber: editItem.passwordNeedsNumber,
                passwordNeedsUppercaseCharacter:
                  editItem.passwordNeedsUppercaseCharacter,
                passwordNeedsSpecialCharacter:
                  editItem.passwordNeedsSpecialCharacter,
                expirePassword: editItem.expirePassword,
                expirePasswordDays: editItem.expirePasswordDays,
                maxLoginAttempts: editItem.maxLoginAttempts,
                samlConfiguration: editItem.samlConfiguration
              }}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
