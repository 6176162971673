import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import Organization from './item';
import appSession from 'stores/AppSession';

export default class OrganizationsStore extends BaseStore {
  @observable items = [];

  @action
  list(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/organizations'
    })
      .then((resp) => {
        this.items = resp.data.map(
          (organization) => new Organization(organization)
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/organizations',
      body
    })
      .then((resp) => {
        const organization = new Organization(resp.data);
        if (!this.items.length) {
          appSession.setOrganization(organization);
        }

        this.items.push(organization);

        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/organizations/${id}`,
      body: rest
    })
      .then(({ data }) => {
        const index = this.items.findIndex((item) => item.id == data.id);
        this.items.splice(index, 1, data);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  delete(organization, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/organizations/${organization.id}`
    })
      .then(() => {
        this.items.replace(
          this.items.filter((item) => item.id !== organization.id)
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
