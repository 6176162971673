import { observable, action } from 'mobx';
import request from 'utils/request';
import BaseStore from 'stores/BaseStore';

export default class OrganizationStore extends BaseStore {
  @observable keys = [];
  @observable data = {};

  constructor(props) {
    super(props);
    Object.assign(this.data, props);
  }

  update(props) {
    Object.assign(this.data, props);
  }

  @action
  fetchKeys(statusKey = 'keys') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/studies/${this.data.id}/keys`
    })
      .then((resp) => {
        this.keys = resp.data;
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
