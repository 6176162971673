import { observable, action } from 'mobx';

class AppSession {
  @observable token = window.localStorage.getItem('jwt');
  @observable loaded = false;
  @observable organization;

  @action
  setOrganization(organization) {
    this.organization = organization;
    if (organization) {
      window.localStorage.setItem('organization', organization.id);
    } else {
      window.localStorage.removeItem('organization');
    }
  }

  @action
  setLoaded(loaded = true) {
    this.loaded = loaded;
  }

  @action
  setToken(token) {
    this.token = token;
    this.token = token;
    if (token) {
      window.localStorage.setItem('jwt', token);
    } else {
      window.localStorage.removeItem('jwt');
    }
  }

  @action
  reset() {
    this.setToken(undefined);
    this.setOrganization(undefined);
    this.setLoaded(false);
  }
}

export default new AppSession();
