import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { reaction } from 'mobx';

import HelpTip from 'components/HelpTip';

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Label
} from 'semantic-ui-react';

const formatTs = (ts) => {
  if (!ts) return 'Never';
  return moment(Math.round(ts * 1000)).format('LLL');
};

const batteryIconForLevel = (level) => {
  if (level > 85) {
    return 'battery full';
  } else if (level > 70) {
    return 'battery three quarters';
  } else if (level >= 40) {
    return 'battery half';
  } else if (level >= 10) {
    return 'battery quarter';
  } else {
    return 'battery empty';
  }
};

const getTokenStatus = (item) => {
  if (!item.oauthCredentialsExpireAt) return '-';
  const { oauthCredentialsLastRefreshedAt, oauthCredentialsExpireAt } = item;
  const lastRefreshTs = Date.parse(oauthCredentialsLastRefreshedAt);
  const expireTs = Date.parse(oauthCredentialsExpireAt);
  let expireDuration = moment.duration(expireTs - Date.now()).humanize();
  let refreshDuration = moment.duration(Date.now() - lastRefreshTs).humanize();
  return `Token expires in ${expireDuration}. Last refreshed ${refreshDuration} ago.`;
};

const getDeviceDetails = (device) => {
  const { type, info } = device;
  if (type === 'garmin') {
    if (!info) return null;
    if (!info.deviceInfo) return null;
    return {
      batteryLevel: device.info.deviceInfo.level,
      ...device.info.deviceInfo
    };
  }
  return device.deviceDetails;
};

const renderPullSyncSummary = (item, sourceName) => {
  return (
    <Table celled collapsing size="small" basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Summary Data Pull</Table.Cell>
          <Table.Cell>{formatTs(item.lastSummarySyncTs)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Full Data Pull</Table.Cell>
          <Table.Cell>{formatTs(item.lastPullDayTs)}</Table.Cell>
        </Table.Row>
        {item.deviceDetails && (
          <Table.Row>
            <Table.Cell>{sourceName} Device Sync</Table.Cell>
            <Table.Cell>
              {formatTs(item.deviceDetails.lastSyncTimeTs)}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

const renderPushSyncSummary = (item) => {
  return (
    <Table celled collapsing size="small" basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Last Data Push</Table.Cell>
          <Table.Cell>{formatTs(item.lastDataPushedTs)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const renderPullStatus = (item, sourceName) => {
  return (
    <div>
      {item.lastError ? (
        <p>
          <Label icon="warning sign" color="orange" content="Error Occurred" />
          <HelpTip title={`${sourceName} Sync Error`} text={item.lastError} />
        </p>
      ) : (
        <p>
          <Label icon="checkmark" color="olive" content="Syncing" />
        </p>
      )}
      <p>{getTokenStatus(item)}</p>
      {item.limitReachedTs && (
        <p>Throttling API calls due to API quota limits.</p>
      )}
    </div>
  );
};

const renderPushStatus = (item, sourceName) => {
  return (
    <div>
      {item.lastError ? (
        <p>
          <Label icon="warning sign" color="orange" content="Error Occurred" />
          <HelpTip title={`${sourceName} Sync Error`} text={item.lastError} />
        </p>
      ) : (
        <p>
          <Label icon="upload" content="Push" />
        </p>
      )}
    </div>
  );
};

const isPushDevice = (item) => {
  if (item.type === 'garmin') {
    return true;
  }
  return false;
};

@inject('devices', 'appSession')
@observer
export default class DevicesList extends React.Component {
  constructor(props) {
    super(props);
    const { appSession, devices } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        devices.list();
      }
    );
  }

  componentDidMount() {
    this.props.devices.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  render() {
    const { devices } = this.props;
    const listStatus = devices.getStatus('list');

    return (
      <Container>
        <Header as="h2">Connected Devices</Header>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Subject Key(s)</Table.HeaderCell>
                <Table.HeaderCell>Device</Table.HeaderCell>
                <Table.HeaderCell>Sync Times</Table.HeaderCell>
                <Table.HeaderCell>Sync Status</Table.HeaderCell>
                <Table.HeaderCell>Date Added</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!devices.items.length && (
                <Table.Row>
                  <Table.Cell>No devices connected yet</Table.Cell>
                </Table.Row>
              )}
              {devices.items.map((item) => {
                const sourceName =
                  item.type.slice(0, 1).toUpperCase() + item.type.slice(1);
                const deviceDetails = getDeviceDetails(item);
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {item.enrollments.map((e) => e.subjectKey).join(', ')}
                      <HelpTip
                        title={`Device ID`}
                        text={
                          <>
                            <p>Connected Device ID = {item.id}</p>
                            <p>User ID = {item.userId}</p>
                            <p>
                              External User ID = {item.externalUserId || 'n/a'}
                            </p>
                            <p>
                              External Device ID =
                              {item.externalDeviceId || 'n/a'}
                            </p>
                          </>
                        }
                      />
                      {item.externalDeviceId && (
                        <p>
                          <small>Unit ID: {item.externalDeviceId}</small>
                        </p>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {deviceDetails ? (
                        <div>
                          <p>{`${sourceName} ${deviceDetails.model}`}</p>
                          {deviceDetails.batteryLevel &&
                            deviceDetails.batteryLevel >= 0 && (
                              <Label
                                size="small"
                                icon={batteryIconForLevel(
                                  deviceDetails.batteryLevel
                                )}
                                content={`Battery: ${deviceDetails.batteryLevel}%`}
                              />
                            )}
                          <HelpTip
                            title={`Device Details`}
                            text={Object.keys(deviceDetails).map((key) => {
                              return (
                                <p
                                  key={key}
                                >{`${key} = ${deviceDetails[key]}`}</p>
                              );
                            })}
                          />
                        </div>
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {isPushDevice(item)
                        ? renderPushSyncSummary(item)
                        : renderPullSyncSummary(item)}
                    </Table.Cell>
                    <Table.Cell>
                      {isPushDevice(item)
                        ? renderPushStatus(item, sourceName)
                        : renderPullStatus(item, sourceName)}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(item.createdAt).format('LLL')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
      </Container>
    );
  }
}
