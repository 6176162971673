import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppWrapper from 'components/AppWrapper';
import List from './List';

@observer
export default class Devices extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <AppWrapper>
        <Switch>
          <Route path={`${match.path}`} exact component={List} />
        </Switch>
      </AppWrapper>
    );
  }
}
