import { observable, action } from 'mobx';

export default class Participants {
  @observable timeRange;
  @observable subject;

  @action
  setTimeRange(timeRange) {
    this.timeRange = timeRange;
  }

  @action
  setSubject(subject) {
    this.subject = subject;
  }

  @action
  reset() {
    this.setSubject(undefined);
    this.setTimeRange(undefined);
  }
}
