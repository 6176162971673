import React from 'react';
import { Message, Button } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import { getToken, parseToken } from 'utils/token';

@inject('auth', 'routing')
@observer
export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    const token = getToken(props);
    this.state = {
      token,
      jwt: token && parseToken(token)
    };
  }
  handleSubmit = () => {
    return this.props.auth.acceptInvite(this.state.token).then((result) => {
      if (result instanceof Error) return;
      this.props.routing.push('/');
    });
  };

  render() {
    const status = this.props.auth.getStatus('acceptInvite');
    const { jwt, token } = this.state;
    return (
      <PageCenter>
        <LogoTitle />

        <div className="content">
          {token && (
            <div className="wrapper">
              <h2>You have been invited to {jwt.organizationName}</h2>
              <p>
                Click the button below to accept the invite and get access to
                the organization
              </p>
              <p>
                {status.error && (
                  <Message error content={status.error.message} />
                )}
                <br />
                <Button
                  loading={status.request}
                  fluid
                  primary
                  content="Accept Invite"
                  onClick={this.handleSubmit}
                />
              </p>
            </div>
          )}
          {(!token || !jwt) && (
            <div className="wrapper">
              <h2>Complete your account</h2>
              <Message error size="huge">
                <Message.Header>No valid token found</Message.Header>
                <Message.Content>
                  Please ensure you either click the email link in the email
                  <br />
                  or copy paste the link in full.
                </Message.Content>
              </Message>
            </div>
          )}
        </div>
      </PageCenter>
    );
  }
}
