import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import AppWrapper from 'components/AppWrapper';
import Members from './Members';
import Invites from './Invites';

import { reaction } from 'mobx';

import {
  Container,
  Header,
  Loader,
  Segment,
  Dimmer,
  Message,
  Dropdown,
  Menu
} from 'semantic-ui-react';

import Link from 'components/Link';
import SecondaryMenu from 'components/SecondaryMenu';

@inject('studies', 'appSession')
@observer
export default class Boot extends React.Component {
  constructor(props) {
    super(props);
    const { appSession, studies } = props;

    this.state = {
      study: studies.items.length ? studies.items[0] : null
    };

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        studies.list().then(() => {
          const { items } = this.props.studies;
          this.setState({ study: items[0] });
        });
      }
    );
  }

  componentDidMount() {
    this.props.studies.list().then(() => {
      const { items } = this.props.studies;
      this.setState({ study: items[0] });
    });
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    const { match, studies } = this.props;
    const listStatus = studies.getStatus('list');
    return (
      <AppWrapper>
        <Container>
          <Header as="h2">Enrollments</Header>
          <SecondaryMenu pointing secondary>
            <Menu.Menu position="left">
              <Menu.Item name="Members" as={Link} to="/enrollments" />
              <Menu.Item name="Invites" as={Link} to="/enrollments/invites" />
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item>
                <Dropdown
                  style={{
                    width: '180px'
                  }}
                  onChange={(e, { value }) => {
                    this.setState({
                      study: studies.items.find(
                        (item) => item.data.id === value
                      )
                    });
                  }}
                  value={this.state.study && this.state.study.data.id}
                  selection
                  options={studies.items.map(({ data }) => {
                    return {
                      text: data.name,
                      value: data.id
                    };
                  })}
                  button
                  compact
                />
              </Menu.Item>
            </Menu.Menu>
          </SecondaryMenu>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}

          {listStatus.success &&
            !studies.items.length && (
              <Message>
                You need to create a Study before see enrollments{' '}
                <Link to="/studies">Go to studies</Link>
              </Message>
            )}

          {this.state.study && (
            <Switch>
              <Route
                path={`${match.path}`}
                exact
                component={(props) => (
                  <Members studyId={this.state.study.data.id} {...props} />
                )}
              />
              <Route
                path={`${match.path}/invites`}
                exact
                component={(props) => (
                  <Invites studyId={this.state.study.data.id} {...props} />
                )}
              />
            </Switch>
          )}
        </Container>
      </AppWrapper>
    );
  }
}
