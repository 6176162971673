import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Menu, Divider } from 'semantic-ui-react';
import Overview from './Overview';
import Participation from './Participation';
import Surveys from './Surveys';
import Wearables from './Wearables';
import AppWrapper from 'components/AppWrapper';
import Link from 'components/Link';
import SecondaryMenu from 'components/SecondaryMenu';
import Filters from './Filters';
import { observer, inject } from 'mobx-react';
import { isLegacyOrganization } from 'utils/legacy';
import { hasPermissions } from '../../utils/rbac';

@inject('filters', 'appSession', 'me')
@observer
export default class Home extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const subject = params.get('subject');
    props.filters.analytics.setSubject(subject);
  }

  componentWillUnmount() {
    this.props.filters.analytics.reset();
  }

  render() {
    const path = this.props.location.pathname;
    const { appSession } = this.props;
    return (
      <AppWrapper>
        <h2 className="page-title">Analytics</h2>
        <SecondaryMenu pointing secondary>
          <Menu.Menu position="left">
            <Menu.Item
              exact
              active={['/', '/analytics'].includes(path)}
              name="Overview"
              as={Link}
              to="/analytics"
            />
            <Menu.Item
              name="Participation"
              as={Link}
              to="/analytics/participation"
            />
            <Menu.Item name="Surveys" as={Link} to="/analytics/surveys" />
            {!isLegacyOrganization(appSession.organization) && (
              <Menu.Item name="Wearables" as={Link} to="/analytics/wearables" />
            )}
          </Menu.Menu>
          <Filters />
        </SecondaryMenu>
        <Divider hidden />
        <Divider hidden />
        <Switch>
          <Route exact path="/analytics/" component={Overview} />
          <Route path="/analytics/participation" component={Participation} />
          <Route path="/analytics/surveys" component={Surveys} />
          <Route path="/analytics/wearables" component={Wearables} />
          <Route exact path="/" component={Overview} />
        </Switch>
      </AppWrapper>
    );
  }
}
