import React from 'react';
import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import { Field } from 'react-final-form';
import { Form, Input, Header, Segment, TextArea } from 'semantic-ui-react';
import { required } from 'utils/validate';
import config from 'config';

export default (props) => {
  const { handleSubmit, errors, id } = props;
  return (
    <Form
      id={id}
      error={Object.keys(errors).length > 0}
      size="large"
      onSubmit={handleSubmit}
    >
      <Form.Field>
        <label>Organization Name</label>
        <Field
          name="name"
          component={FormInput}
          as={Input}
          type="text"
          validate={required}
        />
      </Form.Field>
      <Header as="h4">Password Creation Requirements</Header>
      <Segment>
        <label>Minimum Character Length</label>
        <Field
          name="passwordMinLength"
          component={FormInput}
          as={Input}
          type="number"
        />
        <Field
          as={FormCheckbox}
          label="Require at least one number"
          name="passwordNeedsNumber"
          component={Form.Checkbox}
        />
        <Field
          as={FormCheckbox}
          label="Require at least one upper case character"
          name="passwordNeedsUppercaseCharacter"
          component={Form.Checkbox}
        />
        <Field
          as={FormCheckbox}
          label="Require at least one special (non-alphanumeric) character"
          name="passwordNeedsSpecialCharacter"
          component={Form.Checkbox}
        />
      </Segment>
      <Header as="h4">Login Requirements</Header>
      <Segment>
        <Field
          as={FormCheckbox}
          label="Expire password after a period of time"
          name="expirePassword"
          component={Form.Checkbox}
        />
        <label>Password maximum lifetime (in days)</label>
        <Field
          name="expirePasswordDays"
          component={FormInput}
          as={Input}
          type="number"
        />
        <label>Maximum Login Attempts</label>
        <Field
          name="maxLoginAttempts"
          component={FormInput}
          as={Input}
          type="number"
        />
      </Segment>
      <Header as="h4">SAML Configuration</Header>
      <Segment>
        <label>Entry Point URL</label>
        <Field
          name="samlConfiguration.entryPoint"
          component={FormInput}
          as={Input}
          type="text"
        />
        <label>Issuer URL</label>
        <Field
          name="samlConfiguration.issuer"
          component={FormInput}
          as={Input}
          type="text"
        />
        <label>Certificate</label>
        <Field
          name="samlConfiguration.cert"
          component={FormInput}
          as={TextArea}
          type="text"
        />
        <label>SAML Login URL</label>
        <div className="field">
          <Input readOnly value={`${config.API_URL}/1/auth/${id}/saml/login`} />
        </div>
        <label>SAML Callback URL</label>
        <div className="field">
          <Input
            readOnly
            value={`${config.API_URL}/1/auth/${id}/saml/callback`}
          />
        </div>
      </Segment>
      <Header as="h4">Other</Header>
      <Segment>
        <Field
          as={FormCheckbox}
          label="Disable ePRO functionality"
          name="disableSurveys"
          component={Form.Checkbox}
        />
      </Segment>
    </Form>
  );
};
