import React from 'react';
import Wizard from 'components/Wizard';
import Modal from 'components/Modal';
// import Wrapper from './Wrapper';

import { Form, Message, Button } from 'semantic-ui-react';

import FormInput from 'components/FormInput';
import { Field } from 'react-final-form';
import { required } from 'utils/validate';

export default class ImportWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      showNext: true
    };
    this.wizard = React.createRef();
    this.modal = React.createRef();
  }

  open = () => {
    this.modal.current.open();
  };

  close = () => {
    this.modal.current.close();
  };

  handleCreate = (body) => {
    const { studies } = this.props;
    return studies.create(body).then((result) => {
      if (result instanceof Error) return;
      this.close();
    });
  };

  handleUpdate = (body) => {
    const { studies } = this.props;
    return studies.update(body).then((err) => {
      if (err instanceof Error) return;
      this.close();
    });
  };

  render() {
    const { studies } = this.props;
    const { showSubmit, showNext, showPrev } = this.state;
    const createStatus = studies.getStatus('create');
    const updateStatus = studies.getStatus('update');

    const actions = [
      showPrev && {
        content: 'Prev',
        onClick: () => this.wizard.current.previous()
      },
      showSubmit && {
        content: 'Submit',
        loading: (createStatus || updateStatus).request === true,
        primary: true,
        onClick: () => this.wizard.current.next()
      },
      showNext && {
        content: `Next`,
        primary: true,
        onClick: () => {
          this.wizard.current.next();
        }
      }
    ].filter(Boolean);

    return (
      <Modal
        ref={this.modal}
        title={'Create Import Job'}
        actions={actions}
        onClose={() => {
          if (this.props.onDone) this.props.onDone();

          this.setState({
            currentPage: 0,
            showNext: true,
            showSubmit: false,
            showPrev: false
          });
        }}
      >
        <Wizard
          onSubmit={
            this.props.initialValues ? this.handleUpdate : this.handleCreate
          }
          ref={this.wizard}
          onNext={(page, isFinalPage) => {
            this.setState({
              currentPage: page,
              showPrev: true,
              showSubmit: isFinalPage,
              showNext: !isFinalPage
            });
          }}
          onPrev={(page) => {
            this.setState({
              currentPage: page,
              showSubmit: false,
              showNext: true
            });
          }}
          initialValues={this.props.initialValues}
        >
          <Wizard.Page>
            <h4>Context Configuration</h4>
            <Field
              name="studyId"
              label="Destination Study"
              component={FormInput}
              as={Form.Select}
              options={studies.items.map((study) => {
                return {
                  text: study.data.name,
                  value: study.data.id
                };
              })}
              type="text"
              validate={required}
            />
            <Field
              name="collectionType"
              label="Collection Type"
              component={FormInput}
              as={Form.Select}
              options={[
                {
                  text: 'Wearable',
                  value: 'wearable'
                },
                {
                  text: 'Survey',
                  value: 'survey'
                }
              ]}
              type="text"
              validate={required}
            />
          </Wizard.Page>
          <Wizard.Page>
            <h4>Upload JSON File</h4>
            <p>
              <Button primary icon="json file outline" content="Select File" />
            </p>
          </Wizard.Page>
          <Wizard.Page>
            <h4>Configure Fields</h4>
            <Message error content="Internal Server Error" />
          </Wizard.Page>
        </Wizard>
      </Modal>
    );
  }
}
