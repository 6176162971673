import React, { Component } from 'react';
import { Statistic, Divider, Grid, Message } from 'semantic-ui-react';
import SeriesChart from 'components/charts/Series';
import DonutChart from 'components/charts/Donut';
import MetricsContainer from 'components/containers/Metrics';
import StackedBarChart from 'components/charts/StackedBar';
import NumberWidget from 'components/NumberWidget';
import { observer, inject } from 'mobx-react';
import { hasPermissions } from 'utils/rbac';

function processStacked(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.measurementType.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });

  const x = Object.keys(namespaces).map((key) => {
    return {
      id: key,
      series: data.map((bucket) => {
        const namespace = bucket.measurementType.find(
          (entity) => entity.id == key
        );
        return {
          x: bucket.ts,
          y: namespace ? namespace.count : 0
        };
      })
    };
  });
  return x;
}

function processDeviceModelPie(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.deviceModel.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });
  const a = Object.keys(namespaces).map((key) => {
    return {
      y: namespaces[key],
      x: key
    };
  });
  return a;
}

function processSourcePie(data) {
  const namespaces = {};
  data.forEach((c) => {
    c.sourceType.forEach((c) => {
      if (!namespaces[c.id]) {
        namespaces[c.id] = 0;
      }
      namespaces[c.id] += c.count;
    });
  });
  const a = Object.keys(namespaces).map((key) => {
    return {
      y: namespaces[key],
      x: key.slice(0, 1).toUpperCase() + key.slice(1)
    };
  });
  return a;
}

@inject('me')
@observer
export default class Wearables extends Component {
  render() {
    const { me } = this.props;
    if (!hasPermissions(me.user, 'wearabbleMetrics', 'read'))
      return <Message content="No permission to access this view" />;
    return (
      <div>
        <Statistic.Group
          size="large"
          style={{ justifyContent: 'space-between' }}
        >
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="wearable"
            query={{
              measurementType: 'steps'
            }}
            interval={false}
          >
            <NumberWidget title="Synced Days" field="count" />
          </MetricsContainer>
          <MetricsContainer
            noContainer
            filterType="analytics"
            type="wearable"
            interval={false}
            entities={['enrollmentId']}
            processData={(data) => {
              return { count: Object.keys(data.enrollmentId || {}).length };
            }}
          >
            <NumberWidget title="Active Participants" field="count" />
          </MetricsContainer>
        </Statistic.Group>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <MetricsContainer
          filterType="analytics"
          style={{ marginBottom: '20px' }}
          title="Days Synced"
          type="wearable"
          entities={['measurementType']}
          processData={processStacked}
        >
          <StackedBarChart />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Divider hidden />
              <MetricsContainer
                filterType="analytics"
                title="Device Models"
                type="wearable"
                entities={['deviceModel']}
                processData={processDeviceModelPie}
              >
                <DonutChart height={300} />
              </MetricsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Divider hidden />
              <MetricsContainer
                filterType="analytics"
                title="Device Manufacturers"
                type="wearable"
                entities={['sourceType']}
                processData={processSourcePie}
              >
                <DonutChart height={300} />
              </MetricsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <MetricsContainer
          filterType="analytics"
          type="wearable"
          query={{
            measurementType: 'steps'
          }}
          metricField="totalSteps"
          title="Total Steps"
        >
          <SeriesChart x="ts" y="count" type="bar" />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </div>
    );
  }
}
