import React from 'react';
import ReactMarkdown from 'react-markdown';
import PublicAppWrapper from 'components/PublicAppWrapper';
import EVENTS_API_MD from 'docs/EVENTS_API.md';
import CodeBlock from '../CodeBlock';
import 'github-markdown-css';

import { Divider } from 'semantic-ui-react';

export default class OcpiCredentials extends React.Component {
  state = {};

  render() {
    return (
      <PublicAppWrapper>
        <div className="docs markdown-body">
          <ReactMarkdown
            source={EVENTS_API_MD}
            renderers={{ code: CodeBlock }}
          />
        </div>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </PublicAppWrapper>
    );
  }
}
