export const permissionDefinitions = {
  devices: 'Wearable Devices',
  enrollments: 'Study Enrollments',
  mutations: 'Audit Trail (Read-Only)',
  organizations: 'Organizations',
  roles: 'Roles',
  studies: 'Studies',
  taskDefinitions: 'Task Definitions',
  tasks: 'Computed Tasks (Read-Only)',
  users: 'Administrators',
  surveyMetrics: 'Survey Data',
  wearableMetrics: 'Wearable Data'
};

export const isSuperAdmin = (user) => {
  if (!user) return false;
  if (user.role === 'admin') return true;
  return false;
};

export const hasPermissions = (user, model, accessType) => {
  if (!user) return false;
  if (isSuperAdmin(user)) return true;
  const { adminRoles } = user;
  if (!adminRoles) return false;
  const permissionsByModel = {};
  adminRoles.forEach((role) => {
    Object.keys(role.permissions).map((model) => {
      if (!permissionsByModel[model]) {
        permissionsByModel[model] = [];
      }
      permissionsByModel[model].push(role.permissions[model]);
    });
  });
  if (!permissionsByModel[model]) return false;
  if (accessType === 'write') {
    return permissionsByModel[model].includes('read-write');
  }
  if (accessType === 'read') {
    return (
      permissionsByModel[model].includes('read-write') ||
      permissionsByModel[model].includes('read')
    );
  }
  return false;
};

export const renderUserType = (user) => {
  if (user.role === 'admin') {
    return 'Super Admin';
  }
  return 'User';
};
