import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import request from 'utils/request';

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Button,
  Label
} from 'semantic-ui-react';
import EditEnrollment from 'components/modals/EditEnrollment';
import HelpTip from 'components/HelpTip';
import Modal from 'components/Modal';

function formatStatus(data) {
  if (data.isExcluded) {
    return <Label content="Excluded" icon="hide" />;
  }
  return <Label content="Enrolled" icon="checkmark" />;
}

@inject('taskDefinitions', 'appSession', 'me')
@observer
export default class Home extends React.Component {
  state = {
    enrollments: []
  };

  componentDidMount() {
    this.fetchMembers();
  }

  handleRemove = (item) => {
    request({
      method: 'POST',
      path: `/1/enrollments/${item.id}/soft-delete`,
      body: {
        studyId: item.studyId
      }
    })
      .then(() => {
        document.location.reload();
      })
      .catch((error) => {
        alert(`Could not soft delete participant: ${error.message}`);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.studyId && this.props.studyId !== prevProps.studyId) {
      this.fetchMembers();
    }
  }

  fetchMembers() {
    this.setState({ loading: true, error: null });
    request({
      method: 'GET',
      path: `/1/enrollments?study=${this.props.studyId}`
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          enrollments: data
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e
        });
      });
  }

  handleOnInvite = () => {
    this.fetchEnrollments();
  };

  render() {
    const { enrollments, loading, error } = this.state;

    return (
      <Container>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Subject Key</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Status
                </Table.HeaderCell>
                {enrollments.length > 0 && !!enrollments[0].user && (
                  <Table.HeaderCell>User Email</Table.HeaderCell>
                )}
                <Table.HeaderCell width={3}>Created At</Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!enrollments.length && (
                <Table.Row>
                  <Table.Cell>No enrollments for this study</Table.Cell>
                </Table.Row>
              )}
              {enrollments.map((data) => {
                return (
                  <Table.Row key={data.id}>
                    <Table.Cell>
                      <strong>{data.subjectKey}</strong>
                      <HelpTip
                        float="right"
                        title={`Device ID`}
                        text={
                          <>
                            <p>Subject Key = {data.subjectKey}</p>
                            <p>User ID = {data.userId}</p>
                            <p>Enrollment ID = {data.id}</p>
                          </>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell collapsing textAlign="center">
                      {formatStatus(data)}
                    </Table.Cell>
                    {!!data.user && (
                      <Table.Cell collapsing>{data.user.email}</Table.Cell>
                    )}
                    <Table.Cell collapsing>
                      {moment(data.createdAt).format('LL')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditEnrollment
                        enrollmentId={data.id}
                        studyId={data.studyId}
                        initialValues={{
                          measurements: {}
                        }}
                        trigger={<Button basic icon="edit" />}
                      />

                      <Modal
                        title={`Are you sure you want to soft delete participant "${data.subjectKey}"?`}
                        trigger={
                          <Button basic icon="delete" title="Soft Delete" />
                        }
                        action={{
                          content: 'Delete',
                          onClick: () => this.handleRemove(data)
                        }}
                      >
                        <p>
                          This participant will be soft deleted. This means this
                          participant won't show up in analytics or the data
                          export. Data still exists and can still be submitted.
                        </p>
                        <p>
                          This can be undone only by Litmus technical support
                        </p>
                      </Modal>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {loading && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {error && <Message error content={error.message} />}
        </div>
      </Container>
    );
  }
}
