import React from 'react';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';
import logoInverted from 'assets/logo_inverted.svg';
import Footer from 'components/Footer';
import styled from 'styled-components';

const TopMenu = styled(Menu)`
  &.ui.inverted.menu {
    background: #060439;
    padding: 25px 0 25px 0;
  }

  &.ui.inverted.menu .item {
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-left: 2.2em;
    padding-right: 2.2em;

    &::before {
      background: none;
    }
  }

  &.ui.inverted.menu .item:first-child {
    padding-left: 0;
    padding-right: 1.5em;
  }

  &.ui.inverted.menu .active.item,
  &.ui.inverted.menu .item:hover {
    background: none;
  }

  &.ui.inverted.menu .right.menu .item:first-child::before {
    background: none;
  }

  &.ui.inverted.menu .ui.button.organizationsDropdown {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;

    input.search {
      color: #fff;
    }
  }
`;

@inject('me', 'organizations', 'appSession')
@withRouter
@observer
export default class TopNav extends React.Component {
  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        <TopMenu inverted fixed="top">
          <Container>
            <Menu.Item as={Link} to="/">
              <img
                style={{ width: '110px' }}
                alt="Litmus Health"
                className="logo"
                src={`${logoInverted}`}
              />
            </Menu.Item>
          </Container>
        </TopMenu>
        <Container style={{ marginTop: '160px', minHeight: '500px' }}>
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
