import React from 'react';
import {
  Message,
  Table,
  Button,
  Label,
  Divider,
  Pagination,
  Loader
} from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import { numberWithCommas } from 'utils/formatting';
import styled from 'styled-components';

import HelpTip from 'components/HelpTip';

const StyledTable = styled(Table)`
  .unsortable.unsortable {
    cursor: default !important;

    &:hover {
      background: #f9fafb !important;
    }
  }
`;

export default class LegacyParticipantsTable extends React.Component {
  handleOnPageChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.props.onPageChange(activePage);
  };

  renderTable() {
    const {
      data,
      disablePagination,
      disableFiltering,
      meta,
      status,
      sort,
      page
    } = this.props;

    if (!data || !data.length) {
      if (status.request) {
        return <Loader active />;
      } else {
        return <Message content="No participants added yet" />;
      }
    }

    const sortOrder = sort.order === 'asc' ? 'ascending' : 'descending';

    return (
      <div>
        <Divider hidden />
        <StyledTable celled sortable={!disableFiltering} selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                onClick={() => this.props.onSortChanged('subjectKey')}
                sorted={sort.field === 'subjectKey' ? sortOrder : null}
              >
                Subject ID
              </Table.HeaderCell>
              {false && <Table.HeaderCell>State</Table.HeaderCell>}
              <Table.HeaderCell className="unsortable">
                Activity Classification
                <HelpTip
                  title="Activity Classification"
                  text="Litmus uses 10 different tests to verify the activity status of a subject. If all tests pass, status is set to Normal."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => this.props.onSortChanged('surveySummary.All.to')}
                sorted={
                  sort.field === 'surveySummary.All.to' ? sortOrder : null
                }
              >
                Last Survey
                <HelpTip
                  title="Last Survey"
                  text="This is the last date any survey has been completed by the subject in the mobile application."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() =>
                  this.props.onSortChanged(
                    'fitbitSummary.sleep.dataLastDateStr'
                  )
                }
                sorted={
                  sort.field === 'fitbitSummary.sleep.dataLastDateStr'
                    ? sortOrder
                    : null
                }
              >
                Last Fitbit Sleep
                <HelpTip
                  title="Last Sleep"
                  text="This is the last date any non zero Sleep data has been received from Fitbit in the past 30 days. If no data was found in the past 30 days, last date is not known. Late Fitbit syncs by the subjects can cause delays in data."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() =>
                  this.props.onSortChanged(
                    'fitbitSummary.steps.dataLastDateStr'
                  )
                }
                sorted={
                  sort.field === 'fitbitSummary.steps.dataLastDateStr'
                    ? sortOrder
                    : null
                }
              >
                Last Fitbit Steps
                <HelpTip
                  title="Last Steps"
                  text="This is the last date any non zero Steps data has been received from Fitbit in the past 30 days. If no data was found in the past 30 days, last date is not known. Late Fitbit syncs by the subjects can cause delays in data."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() =>
                  this.props.onSortChanged('surveySummary.All.total')
                }
                sorted={
                  sort.field === 'surveySummary.All.total' ? sortOrder : null
                }
              >
                Total Events
                <HelpTip
                  title="Total Events"
                  text="Total number Fitbit and Survey events."
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => this.props.onSortChanged('joinedAt')}
                sorted={sort.field === 'joinedAt' ? sortOrder : null}
              >
                Enrolled
                <HelpTip
                  title="Enrolled"
                  text="Date that the subject joined the study in the mobile application."
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item) => {
              const activityStatuses = item.activityStatuses || [];
              return (
                <Table.Row
                  key={item._id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.onSelect(item.subjectKey)}
                >
                  <Table.Cell width={1}>{item.subjectKey}</Table.Cell>
                  {false && (
                    <Table.Cell width={2}>
                      {item.isActive ? (
                        <Label color="blue" content="Active" />
                      ) : (
                        <Label color="white" content="Inactive" />
                      )}
                    </Table.Cell>
                  )}
                  <Table.Cell width={5}>
                    {activityStatuses.map((activityStatus) => {
                      return (
                        <Label
                          size="tiny"
                          key={activityStatus.id}
                          color={activityStatus.color}
                          content={activityStatus.name}
                          icon={activityStatus.icon}
                          title={activityStatus.reason}
                          style={{ marginBottom: '3px' }}
                        />
                      );
                    })}
                    <HelpTip
                      title={`Activity Classifications for ${item.subjectKey}`}
                      icon="list"
                      text={
                        <Table inverted>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={4}>
                                Status
                              </Table.HeaderCell>
                              <Table.HeaderCell width={4}>
                                Type
                              </Table.HeaderCell>
                              <Table.HeaderCell width={8}>
                                Reason
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {activityStatuses.map((activityStatus) => {
                              return (
                                <Table.Row key={activityStatus.id}>
                                  <Table.Cell>{activityStatus.name}</Table.Cell>
                                  <Table.Cell>
                                    {activityStatus.typeName}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {activityStatus.reason}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {item.surveySummary && item.surveySummary.All.to ? (
                      <TimeAgo
                        date={Math.floor(item.surveySummary.All.to) * 1000}
                      />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {item.fitbitSummary && item.fitbitSummary.sleep
                      ? item.fitbitSummary.sleep.dataLastDateStr
                      : '-'}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {item.fitbitSummary && item.fitbitSummary.steps
                      ? item.fitbitSummary.steps.dataLastDateStr
                      : '-'}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {item.surveySummary &&
                      numberWithCommas(item.surveySummary.All.total)}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <TimeAgo
                      date={Date.parse(item.createdAt || item.joinedAt)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {!disablePagination && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="7" textAlign="center">
                  <Pagination
                    floated="right"
                    activePage={page}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    onPageChange={this.handleOnPageChange}
                    totalPages={Math.ceil(meta.total / meta.limit)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </StyledTable>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderTable()}
        <Divider hidden />
        <Button
          primary
          icon="download"
          content="Export CSV"
          onClick={() => this.props.onExport()}
        />
        <Divider hidden />
      </div>
    );
  }
}
