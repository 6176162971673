import React from 'react';
import FormInput from 'components/FormInput';
import arrayMutators from 'final-form-arrays';

import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Input, Button } from 'semantic-ui-react';
import { required } from 'utils/validate';
import { FieldArray } from 'react-final-form-arrays';
import { stepTypes, getStepTypeLabel } from 'utils/types';

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export default (props) => {
  return (
    <FinalForm
      onSubmit={props.onSubmit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={props.initialValues}
      render={({ handleSubmit, errors, initialValues }) => (
        <Form
          id={props.id}
          error={Object.keys(errors).length > 0}
          size="large"
          onSubmit={handleSubmit}
        >
          <Form.Field>
            <label>Type</label>
            {props.isUpdate ? (
              <Input
                readOnly
                style={{ marginBottom: '1em' }}
                type="text"
                input={{ value: getStepTypeLabel(initialValues.type) }}
              />
            ) : (
              <Field
                name="type"
                component={FormInput}
                as={Form.Select}
                options={stepTypes.map((step) => {
                  return {
                    text: step.label,
                    value: step.id
                  };
                })}
                type="text"
                validate={required}
              />
            )}
            <label>Question</label>
            <Field
              name="question"
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />
            <label>Namespace</label>
            <Field
              name="namespace"
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />
            <label>Button Text</label>
            <Field
              name="buttonText"
              component={FormInput}
              as={Input}
              type="text"
              validate={required}
            />

            <Condition when="type" is="MultipleChoiceSingleResponseStep">
              <label>Choices</label>
              <FieldArray name="textChoices">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => (
                      <div key={name}>
                        <label>Choice. #{index + 1}</label>
                        <Field
                          name={name}
                          component={FormInput}
                          label={
                            <Button
                              style={{ width: '40px' }}
                              icon="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                fields.remove(index);
                              }}
                            />
                          }
                          labelPosition="right"
                          as={Input}
                          type="text"
                          validate={required}
                        />
                      </div>
                    ))}
                    <Button
                      style={{ marginTop: '10px' }}
                      primary
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        fields.push('');
                      }}
                    >
                      Add Choice
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Condition>
          </Form.Field>
        </Form>
      )}
    />
  );
};
