import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import Study from './item';
import appSession from 'stores/AppSession';

export default class StudiesStore extends BaseStore {
  @observable items = [];

  @action
  list(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/studies',
      query: {
        organizationId: appSession.organization.id
      }
    })
      .then((resp) => {
        this.items = resp.data.map((study) => new Study(study));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/studies',
      body: {
        ...body,
        organizationId: appSession.organization.id
      }
    })
      .then((resp) => {
        const study = new Study(resp.data);
        this.items.push(study);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/studies/${id}`,
      body: rest
    })
      .then(({ data }) => {
        const index = this.items.findIndex((item) => item.data.id == data.id);
        this.items.splice(index, 1, new Study(data));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  delete(study, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/studies/${study.data.id}`
    })
      .then(() => {
        this.items.replace(
          this.items.filter((item) => item.data.id !== study.data.id)
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
