import React, { Component } from 'react';

import { observer, inject } from 'mobx-react';
import { Statistic, Divider, Message } from 'semantic-ui-react';
import MetricsContainer from 'components/containers/Metrics';
import SeriesChart from 'components/charts/Series';
import NumberWidget from 'components/NumberWidget';
import { hasPermissions } from 'utils/rbac';

@inject('me')
@observer
export default class Home extends Component {
  render() {
    const { me } = this.props;
    if (!hasPermissions(me.user, 'surveyMetrics', 'read'))
      return <Message content="No permission to access this view" />;
    return (
      <div>
        <Statistic.Group
          size="large"
          style={{ justifyContent: 'space-between' }}
        >
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="participants"
            interval={false}
            entities={['userId']}
            processData={(data) => {
              return { count: Object.keys(data.userId || {}).length };
            }}
          >
            <NumberWidget title="Unique Participants" field="count" />
          </MetricsContainer>
          <MetricsContainer
            noContainer
            filterType="analytics"
            type="participants"
            interval={false}
            entities={['studyId']}
            processData={(data) => {
              return { count: Object.keys(data.studyId || {}).length };
            }}
          >
            <NumberWidget title="Studies" field="count" />
          </MetricsContainer>
          <MetricsContainer
            filterType="analytics"
            noContainer
            type="survey"
            interval={false}
          >
            <NumberWidget title="Survey Events" field="count" />
          </MetricsContainer>
          {/*
          <Statistic>
            <Statistic.Value>
              {numberWithCommas(Math.round(stats.fitbitEvents / 1000 / 1000))}M
            </Statistic.Value>
            <Statistic.Label>Fitbit Events</Statistic.Label>
          </Statistic>
          */}
        </Statistic.Group>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <MetricsContainer
          filterType="analytics"
          type="survey"
          title="Survey Events Over Time"
        >
          <SeriesChart x="ts" y="count" type="bar" />
        </MetricsContainer>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {/*
        <Divider />
        <Divider hidden />
        <Divider hidden />
        <Header as="h2" textAlign="center">
          Fitbit Events Over Time
        </Header>
        <SeriesChart type="line" data={fitbitEventsSeries} />
        */}
      </div>
    );
  }
}
