import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import { omit } from 'lodash';

export default class TaskDefinition extends BaseStore {
  @observable steps = [];

  @observable data = {};

  constructor(props) {
    super(props);
    this.assign(props);
  }

  @action
  assign(props) {
    this.id = props.id;
    Object.assign(this.data, omit(props, ['steps']));
    this.steps.replace(props.steps);
  }

  @action
  addStep(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/task-definitions/${this.id}/steps`,
      body: {
        ...body
      }
    })
      .then(({ data }) => {
        this.assign(data);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateStep({ id, ...body }, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/task-definitions/${this.id}/steps/${id}`,
      body
    })
      .then(({ data }) => {
        this.assign(data);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  deleteStep(step, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/task-definitions/${this.id}/steps/${step.id}`
    })
      .then(() => {
        this.steps.replace(this.steps.filter((item) => item.id !== step.id));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
