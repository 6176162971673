import { configure } from 'mobx';

import { RouterStore } from 'mobx-react-router';

import Auth from 'stores/Auth';
import appSession from 'stores/AppSession';
import filters from 'stores/Filters';
import TaskDefinitions from 'stores/TaskDefinitions';
import Me from 'stores/Me';
import Organizations from 'stores/Organizations';
import Studies from 'stores/Studies';
import Mutations from 'stores/Mutations';
import Devices from 'stores/Devices';
import Users from 'stores/Users';
import Roles from 'stores/Roles';

configure({
  enforceActions: 'strict'
});

const routing = new RouterStore();

const stores = {
  filters,
  appSession,
  routing,
  auth: new Auth(),
  me: new Me(),
  organizations: new Organizations(),
  studies: new Studies(),
  taskDefinitions: new TaskDefinitions(),
  mutations: new Mutations(),
  devices: new Devices(),
  users: new Users(),
  roles: new Roles()
};

export default stores;
