import React from 'react';
import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import { Field } from 'react-final-form';
import { Form, Input, Header, Segment, TextArea } from 'semantic-ui-react';
import { required } from 'utils/validate';
import { permissionDefinitions } from 'utils/rbac';
import config from 'config';

const permissionEnum = ['none', 'read', 'read-write'];

const permissionOptions = permissionEnum.map((key) => {
  return {
    key,
    text: key,
    value: key
  };
});

export default (props) => {
  const { handleSubmit, errors, id } = props;
  return (
    <Form
      id={id}
      error={Object.keys(errors).length > 0}
      size="large"
      onSubmit={handleSubmit}
    >
      <Form.Field>
        <label>Role Name</label>
        <Field name="name" component={FormInput} as={Input} type="text" />
      </Form.Field>
      <Header as="h4">Permissions</Header>
      <Segment>
        {Object.keys(permissionDefinitions).map((model) => {
          return (
            <Field
              name={`permissions.${model}`}
              label={`Administration of ${permissionDefinitions[model]}`}
              component={FormInput}
              as={Form.Select}
              options={permissionOptions}
              type="text"
            />
          );
        })}
      </Segment>
    </Form>
  );
};
