import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import InviteForm from '../InviteForm';
import request from 'utils/request';

import {
  Container,
  Header,
  Table,
  Button,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal
} from 'semantic-ui-react';
import { hasPermissions } from 'utils/rbac';

@inject('taskDefinitions', 'appSession', 'me')
@observer
export default class Home extends React.Component {
  state = {
    showInviteDialog: false,
    invites: []
  };

  componentDidMount() {
    this.fetchInvites();
  }

  componentDidUpdate(prevProps) {
    if (this.props.studyId && this.props.studyId !== prevProps.studyId) {
      this.fetchInvites();
    }
  }

  fetchInvites() {
    this.setState({ loading: true, error: null });
    request({
      method: 'GET',
      path: `/1/enrollments/invites?study=${this.props.studyId}`
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          invites: data
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e
        });
      });
  }

  handleCloseDialog = () => {
    this.setState({
      showInviteDialog: false
    });
  };

  handleOpenDialog = () => {
    this.setState({
      showInviteDialog: true
    });
  };

  handleOnInvite = () => {
    this.fetchInvites();
  };

  render() {
    const { invites, loading, error } = this.state;
    const { me } = this.props;
    return (
      <Container>
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell width={3}>Subject Key</Table.HeaderCell>
                <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                <Table.HeaderCell width={3}>Invited At</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!invites.length && (
                <Table.Row>
                  <Table.Cell>No invites for this study</Table.Cell>
                </Table.Row>
              )}
              {invites.map((data) => {
                return (
                  <Table.Row key={data.id}>
                    <Table.Cell>
                      <Header as="h4">
                        <Header.Content>{data.email}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell collapsing>{data.subjectKey}</Table.Cell>
                    <Table.Cell collapsing>{data.status}</Table.Cell>
                    <Table.Cell collapsing>
                      {moment(data.createdAt).format('LL')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {loading && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {error && <Message error content={error.message} />}
        </div>

        <Modal
          open={this.state.showInviteDialog}
          onClose={this.handleCloseDialog}
          closeOnEscape
          size="tiny"
          trigger={
            <Button
              style={{ marginTop: '20px' }}
              onClick={this.handleOpenDialog}
              primary
              content="Enroll Participants by Email"
              disabled={!hasPermissions(me.user, 'enrollments', 'write')}
            />
          }
          title="Create Task Definition"
        >
          <InviteForm
            onClose={this.handleCloseDialog}
            onSubmit={this.handleOnInvite}
            studyId={this.props.studyId}
          />
        </Modal>
      </Container>
    );
  }
}
