import React from 'react';
import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Label
} from 'semantic-ui-react';
import { observer } from 'mobx-react';

@observer
export default class StudyKeys extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.study.fetchKeys();
  }

  render() {
    const status = this.props.study.getStatus('keys');

    if (status.request) {
      return (
        <Segment style={{ height: '100px' }}>
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        </Segment>
      );
    }

    if (status.error) {
      return <Message error content={status.error.message} />;
    }

    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={7}>Subject Key</Table.HeaderCell>
              <Table.HeaderCell width={8}>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
        <div
          style={{
            marginTop: '-20px',
            display: 'block',
            height: '400px',
            overflow: 'auto'
          }}
        >
          <Table celled>
            <Table.Header style={{ display: 'none' }}>
              <Table.Row>
                <Table.HeaderCell width={7}>Subject Key</Table.HeaderCell>
                <Table.HeaderCell width={8}>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body style={{}}>
              {this.props.study.keys.map(({ id, used, reserved }) => {
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>
                      <Label
                        content={used || reserved ? 'Used' : 'Available'}
                        color={used || reserved ? 'orange' : 'olive'}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
