import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';

export default class OrganizationStore extends BaseStore {
  constructor(props) {
    super(props);
    Object.assign(this, props);
  }

  update(props) {
    Object.assign(this, props);
  }

  @observable members = [];
  @observable users = [];

  @action
  fetchMembers(statusKey = 'members') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/organizations/${this.id}/members`
    })
      .then((resp) => {
        this.members = resp.data;
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  fetchUsers(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/organizations/${this.id}/users`
    })
      .then((resp) => {
        this.users = resp.data;
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  setRoles(ids, role, statusKey = 'setRoles') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/organizations/${this.id}/members/roles`,
      body: {
        ids,
        role
      }
    })
      .then(() => {
        ids.forEach((id) => {
          const index = this.members.findIndex((item) => item.id == id);
          this.members.splice(index, 1, { ...this.members[index], role });
        });
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  removeMembers(ids, statusKey) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/organizations/${this.id}/members`,
      body: {
        ids
      }
    })
      .then(() => {
        this.members.replace(
          this.members.filter((member) => !ids.includes(member.id))
        );
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  invite(emails, role, statusKey, roleId) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/organizations/${this.id}/invite`,
      body: {
        emails,
        role,
        roleId
      }
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateUser(body, statusKey = 'updateUser') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/organizations/${this.id}/members/${id}`,
      body: rest
    })
      .then(() => {
        return this.fetchUsers().then(() => {
          status.success();
        });
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
