import React from 'react';
import { observer, inject } from 'mobx-react';

import { Route } from 'react-router-dom';
import Ensure from 'components/Ensure';

@inject('me', 'appSession')
@observer
class Wrapper extends React.Component {
  render() {
    const { appSession, getComponent, ...rest } = this.props;
    const Component = getComponent(!!appSession.token);
    if (!appSession.token) {
      return <Component {...rest} />;
    }

    if (!appSession.loaded) {
      return <Ensure />;
    }

    return (
      <Ensure>
        <Component {...rest} />
      </Ensure>
    );
  }
}

@observer
export default class Index extends React.Component {
  render() {
    const { ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Wrapper {...props} {...this.props} />;
        }}
      />
    );
  }
}
