export const required = (value) => {
  if ((value && value.length === 0) || !value) {
    return 'Required';
  }
  return undefined;
};

export const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const passwordRegexp = /^(?=.*[\d])(?=.*[a-z]).{8,}$/i;

export const email = (value) =>
  !value || !emailRegexp.test(value) ? 'Invalid email address' : undefined;

export const password = (value = '') => {
  if (!passwordRegexp.test(value)) {
    return `Use at least one letter, one numeral, and seven characters.`;
  }
  return undefined;
};
