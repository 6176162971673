import React from 'react';

import { format } from 'd3-format';
import { sum } from 'lodash';

import {
  colorScale,
  formatDateTickValues,
  selectYFormatter
} from 'utils/analytics';

import { Message } from 'semantic-ui-react';

import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryLegend,
  VictoryLine,
  VictoryLabel
} from 'victory';

const bigNumber = format('.2s');

const styles = {
  axisTime: {
    grid: {
      stroke: '#cdcdcd',
      strokeWidth: 0.5,
      strokeDasharray: '5, 5'
    },
    axis: { stroke: '#2a2a2a', strokeWidth: 1 },
    ticks: {
      size: 2,
      stroke: '#2A2A2A',
      strokeWidth: 1
    },
    tickLabels: {
      fill: '#2A2A2A',
      fontSize: 7,
      fontFamily: '"Lato", sans-serif'
    }
  },
  axisValue: {
    grid: {
      stroke: '#cdcdcd',
      strokeWidth: 0.5,
      strokeDasharray: '5, 5'
    },
    axis: { stroke: '#2a2a2a', strokeWidth: 1 },
    ticks: {
      size: 2,
      stroke: 'black',
      strokeWidth: 1
    },
    tickLabels: {
      fill: '#2A2A2A',
      fontSize: 7,
      fontFamily: '"Lato", sans-serif'
    }
  }
};

export default class StackedBarChart extends React.Component {
  render() {
    const { data, markers = [] } = this.props;
    let defaultYFormatter = bigNumber;
    if (data && data.length) {
      defaultYFormatter = selectYFormatter(data[0].series, 'y');
    }
    const sumValues = [...new Array(data[0] ? data[0].series.length : 0)].map(
      (_, index) => {
        return sum(data.map((item) => item.series[index].y));
      }
    );

    const maxY = Math.max(...sumValues, 0);

    const yFormatter = this.props.yFormatter || defaultYFormatter;

    return (
      <div>
        {maxY === 0 && (
          <Message
            size="big"
            style={{
              top: '50%',
              position: 'absolute',
              left: 0,
              right: 0,
              margin: 'auto',
              width: '350px',
              textAlign: 'center',
              background: 'rgba(241, 241, 241, 1)',
              zIndex: 2,
              boxShadow: 'none'
            }}
            content={'No data for this time range'}
          />
        )}
        <VictoryChart
          padding={{ left: 30, right: 5, top: 20, bottom: 25 }}
          height={240}
        >
          <VictoryLegend
            key="legend"
            colorScale={colorScale}
            x={40}
            y={0}
            itemsPerRow={5}
            style={{
              border: {},
              labels: { fontSize: 6, fontFamily: 'Lato, sans-serif' }
            }}
            orientation="horizontal"
            data={this.props.data.map((c) => {
              return { name: c.id };
            })}
          />
          <VictoryStack colorScale={colorScale}>
            {this.props.data.map((data) => {
              return <VictoryBar data={data.series} key={data.id} />;
            })}
          </VictoryStack>
          <VictoryAxis
            dependentAxis
            orientation="left"
            style={styles.axisValue}
            tickFormat={yFormatter}
          />
          <VictoryAxis
            tickFormat={formatDateTickValues}
            tickCount={10}
            scale="time"
            style={styles.axisTime}
          />
          {markers.map((marker) => {
            return (
              <VictoryLine
                key={marker.text}
                style={{
                  data: {
                    strokeWidth: 1,
                    stroke: colorScale[2],
                    strokeDasharray: 2
                  },
                  labels: { angle: 90, fill: colorScale[2], fontSize: 10 }
                }}
                labels={[marker.text]}
                labelComponent={<VictoryLabel y={50} />}
                data={[
                  { x: new Date(marker.ts), y: 0 },
                  { x: new Date(marker.ts), y: maxY || 1 }
                ]}
              />
            );
          })}
        </VictoryChart>
      </div>
    );
  }
}
