import React from 'react';
import { Form, Message, Modal, Button } from 'semantic-ui-react';
import { set } from 'lodash';
import request from 'utils/request';

const genderOptions = [
  {
    text: 'Male',
    value: 'male',
    key: 'male'
  },
  {
    text: 'Female',
    value: 'female',
    key: 'female'
  }
];

export default class EditEnrollment extends React.Component {
  static defaultProps = {
    initialValues: {}
  };

  state = {
    open: false,
    formValues: {
      ...this.props.initialValues
    },
    loading: false,
    error: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues }
      });
    }
  }

  handleSubmit = () => {
    const { studyId, enrollmentId } = this.props;
    const { formValues } = this.state;
    this.setState({
      submitted: true,
      loading: true
    });
    request({
      method: 'PATCH',
      path: `/1/enrollments/${enrollmentId}`,
      body: {
        studyId,
        ...formValues
      }
    })
      .then(() => {
        this.setState({ loading: false, error: null, open: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    const { formValues } = this.state;
    set(formValues, name, value);
    this.setState({
      submitted: false,
      formValues
    });
  }

  render() {
    const { initialValues, trigger } = this.props;
    const { formValues = {}, submitted, open, loading, error } = this.state;
    const { measurements = {} } = formValues;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>Edit Subject Information</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form
            id="edit-form"
            autoComplete="disabled"
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}
          >
            {false && (
              <>
                <Form.Dropdown
                  value={measurements.gender}
                  autoComplete="disabled"
                  name="measurements.gender"
                  label="Sex"
                  selection
                  options={genderOptions}
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  value={measurements.weight}
                  autoComplete="disabled"
                  name="measurements.weight"
                  label="Weight (KG)"
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  value={measurements.height}
                  autoComplete="disabled"
                  name="measurements.height"
                  label="Height (Meters)"
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  value={measurements.age}
                  autoComplete="disabled"
                  name="measurements.age"
                  label="Age (Years)"
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  value={measurements.sleepStart}
                  autoComplete="disabled"
                  name="measurements.sleepStart"
                  label="Daily Bed Time (seconds after midnight)"
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
                <Form.Input
                  value={measurements.sleepEnd}
                  autoComplete="disabled"
                  name="measurements.sleepEnd"
                  label="Daily Wake up Time (seconds after midnight)"
                  type="text"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              </>
            )}
            <Form.Checkbox
              checked={formValues.isExcluded}
              name="isExcluded"
              label="Exclude this participant from study (e.g. for test users)"
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button loading={loading} primary content={'Save'} form="edit-form" />
        </Modal.Actions>
      </Modal>
    );
  }
}
