import React from 'react';
import { Menu, Dropdown, Input, Form, Icon } from 'semantic-ui-react';
import { activityClassification } from 'utils/analytics';
import { observer, inject } from 'mobx-react';
import { append } from 'utils/url';
import styled from 'styled-components';
import DateRangePicker from 'components/DateRangePicker';

const SecondaryMenu = styled(Menu)`
  &.ui.secondary.secondary {
    border-bottom: 4px solid #e6e6e6;
  }

  .menu.left .item {
    font-size: 24px;
    color: #13009b;
    font-weight: bold;
    padding: 0.6em 0 !important;
    margin: 0em 2em -4px 0em !important;
  }

  .menu.left .item.item {
    border-bottom-width: 4px;
    border-color: transparent;
  }

  .menu.left .active.item.item,
  .menu.left .active.item.item:hover {
    color: #13009b;
    border-color: #00ffb0;
  }
`;

const SearchInput = styled(Input)`
  input {
    border: 0 !important;
    font-size: 16px !important;
  }
`;

@inject('filters', 'routing')
@observer
export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    const filter = props.filters.participants;
    this.state = {
      lookupSubject: filter.subject
    };
  }

  getFilter() {
    return this.props.filters.participants;
  }

  handleTimeRangeChange = (timeRange) => {
    this.getFilter().setTimeRange(timeRange);
  };

  handleClassificationChange = (e, { value }) => {
    this.props.onClassificationChange(value === 'all' ? undefined : value);
  };

  handleOnLookup = () => {
    const subject = this.state.lookupSubject;
    this.getFilter().setSubject(subject);
    this.props.routing.push(
      append(window.location.pathname + window.location.search, {
        subject
      })
    );
  };

  render() {
    const options = [
      { value: 'all', text: 'All Classification Types', icon: 'asterisk' },
      ...activityClassification.map((c) => {
        return { text: c.name, value: c.id, icon: c.icon };
      })
    ];

    const timeRange = this.getFilter().timeRange;

    return (
      <SecondaryMenu pointing secondary>
        <Menu.Menu position="right">
          <Dropdown
            item
            defaultValue="all"
            options={options}
            labeled
            onChange={this.handleClassificationChange}
            style={{ fontSize: '16px', fontWeight: 'bold' }}
          />
          <Menu.Item as={Form} onSubmit={this.handleOnLookup}>
            <SearchInput
              onChange={(e, { value }) =>
                this.setState({ lookupSubject: value })
              }
              value={this.state.lookupSubject}
              icon={
                this.state.lookupSubject ? (
                  <Icon
                    name="delete"
                    link
                    onClick={() => {
                      this.setState({ lookupSubject: '' }, () =>
                        this.handleOnLookup()
                      );
                    }}
                  />
                ) : (
                  'search'
                )
              }
              placeholder="Lookup subject ..."
            />
          </Menu.Item>
          <Menu.Item>
            <DateRangePicker
              label="Occurred"
              timeRange={timeRange}
              onChange={this.handleTimeRangeChange}
            />
          </Menu.Item>
        </Menu.Menu>
      </SecondaryMenu>
    );
  }
}
