import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import request from 'utils/request';
import { Loader, Dimmer, Message, Segment, Header } from 'semantic-ui-react';
import { isLegacyOrganization } from 'utils/legacy';

@inject('appSession', 'filters')
@observer
export default class Metrics extends React.Component {
  state = {
    status: {},
    data: [],
    meta: {}
  };

  constructor(props) {
    super(props);

    this.fetchData();
    this.reactions = [];

    if (!this.props.filterType) {
      throw Error('no filterType');
    }

    const filter = this.props.filters[this.props.filterType];

    this.reactions.push(
      reaction(
        () => filter.timeRange,
        () => {
          this.fetchData();
        }
      )
    );

    this.reactions.push(
      reaction(
        () => filter.subject,
        () => {
          this.fetchData();
        }
      )
    );

    this.reactions.push(
      reaction(
        () => this.props.appSession.organization,
        () => {
          this.fetchData();
        }
      )
    );
  }

  componentWillUnmount() {
    this.reactions.forEach((dispose) => {
      dispose();
    });
  }

  fetchData = () => {
    const {
      appSession,
      type,
      interval,
      entities = undefined,
      query = undefined,
      metricOperation = undefined,
      metricField = undefined
    } = this.props;

    const filter = this.props.filters[this.props.filterType];

    this.setState({
      status: { request: true }
    });

    const bodyQuery = {
      ...query,
      ...(filter.subject ? { subject: filter.subject } : {})
    };

    let path = `/1/analytics/${type}/metrics`;
    if (isLegacyOrganization(appSession.organization)) {
      path = `/1/legacy/${type}/metrics`;
    }
    request({
      method: 'POST',
      path,
      query: { organization: appSession.organization.id },
      body: {
        query: Object.keys(bodyQuery).length ? bodyQuery : undefined,
        interval:
          typeof interval === 'function'
            ? interval(filter.timeRange)
            : interval,
        entities,
        timeRange: filter.timeRange || undefined,
        metricField,
        metricOperation
      }
    })
      .then((result) => {
        this.setState({
          meta: result.meta,
          data: result.data,
          status: { success: true }
        });
      })
      .catch((e) => {
        this.setState({
          status: {
            error: e
          }
        });
      });
  };

  render() {
    const { style = {}, className, processData, noContainer } = this.props;
    const { status, data } = this.state;

    const filter = this.props.filters[this.props.filterType];

    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        data: processData ? processData(data) : data,
        status: status,
        timeRange: filter.timeRange
      });
    });

    if (noContainer) {
      return children;
    }

    return (
      <Segment
        style={{
          border: '0',
          boxShadow: 'none',
          borderRadius: '0',
          borderRight: '8px solid #13009B',
          background: '#F9F9F9',
          padding: '60px',
          ...style
        }}
        className={className}
      >
        {this.props.title && (
          <Header
            as="h2"
            content={this.props.title}
            style={{ color: '#000078', marginBottom: '40px', fontSize: '32px' }}
          />
        )}
        {!status.success && (
          <Dimmer
            style={{ zIndex: 1, background: '#f9f9f9', alignItems: 'inherit' }}
            active
          >
            {status.request && <Loader />}
            {status.error && (
              <Message
                style={{ maxWidth: '60%', margin: '0 auto' }}
                error
                content={status.error.message}
              />
            )}
          </Dimmer>
        )}
        {children}
      </Segment>
    );
  }
}
