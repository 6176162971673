import { action } from 'mobx';
import BaseStore from './BaseStore';
import request from 'utils/request';

import appSession from 'stores/AppSession';

export default class AuthStore extends BaseStore {
  @action
  setPassword(body, statusKey) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/set-password',
      body
    })
      .then((resp) => {
        appSession.reset();
        appSession.setToken(resp.data.token);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  login(body, statusKey, onPasswordExpired) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/login',
      body: body
    })
      .then((resp) => {
        if (resp.data.expired) {
          return onPasswordExpired(resp.data.tempToken);
        }
        appSession.reset();
        appSession.setToken(resp.data.token);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  apply(body, statusKey) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/apply',
      body
    })
      .then(() => {
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  register(body, statusKey = 'register') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/admin/register',
      body
    })
      .then((resp) => {
        appSession.reset();
        appSession.setToken(resp.data.token);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  acceptInvite(token, statusKey = 'acceptInvite') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/auth/admin/accept-invite',
      body: {
        token
      }
    })
      .then((resp) => {
        appSession.reset();
        appSession.setToken(resp.data.token);
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
