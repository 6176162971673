import React from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import request from 'utils/request';
import { Loader, Dimmer, Message, Segment, Header } from 'semantic-ui-react';
import { isLegacyOrganization } from 'utils/legacy';

@inject('appSession', 'filters')
@observer
export default class Metrics extends React.Component {
  state = {
    status: { request: true },
    data: [],
    meta: {}
  };

  constructor(props) {
    super(props);

    if (!this.props.filterType) {
      throw Error('no filterType');
    }

    const filter = this.props.filters[this.props.filterType];

    this.fetchData();
    this.reactions = [];
    this.reactions.push(
      reaction(
        () => filter.timeRange,
        () => {
          this.fetchData();
        }
      )
    );

    this.reactions.push(
      reaction(
        () => filter.subject,
        () => {
          this.fetchData();
        }
      )
    );

    this.reactions.push(
      reaction(
        () => this.props.appSession.organization,
        () => {
          this.fetchData();
        }
      )
    );
  }

  getFilter() {
    return this.props.filters[this.props.filterType];
  }

  componentWillUnmount() {
    this.reactions.forEach((dispose) => {
      dispose();
    });
  }

  componentDidUpdate(prevProps) {
    const { sort, skip, limit, query } = this.props;
    if (
      prevProps.skip !== skip ||
      prevProps.limit !== limit ||
      prevProps.sort !== sort ||
      prevProps.query !== query
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const { appSession, type, query, sort, skip, limit } = this.props;
    this.setState({
      status: { request: true }
    });
    const { subject, timeRange } = this.getFilter();

    const bodyQuery = {
      ...query,
      ...(subject ? { subject } : {})
    };
    let path = `/1/analytics/${type}/entities`;
    if (isLegacyOrganization(appSession.organization)) {
      path = `/1/legacy/${type}/entities`;
    }
    request({
      method: 'POST',
      path,
      query: { organization: appSession.organization.id },
      body: {
        timeRange,
        query: Object.keys(bodyQuery).length ? bodyQuery : undefined,
        sort,
        skip,
        limit
      }
    })
      .then((result) => {
        this.setState({
          meta: result.meta,
          data: result.data,
          status: { success: true }
        });
      })
      .catch((e) => {
        this.setState({
          status: {
            error: e
          }
        });
      });
  };

  toCsv(mapping, filename = 'export.csv') {
    const { data } = this.state;
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `${Object.keys(mapping).join(',')}\r\n`;
    csvContent += data
      .map((row) => {
        return Object.keys(mapping)
          .map((key) => {
            const fn = mapping[key];
            return fn(row);
          })
          .join(',');
      })
      .join('\r\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    link.innerHTML = `Download ${filename}`;
    document.body.appendChild(link);
    link.click();
  }

  render() {
    const { style = {}, className, processData, noContainer } = this.props;
    const { status, data, meta } = this.state;

    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        data: processData ? processData(data) : data,
        status: status,
        meta
      });
    });

    if (noContainer) {
      return children;
    }

    return (
      <Segment
        style={{
          border: 0,
          boxShadow: 'none',
          ...style
        }}
        className={className}
      >
        {this.props.title && (
          <Header as="h2" textAlign="center" content={this.props.title} />
        )}
        {!status.success && (
          <Dimmer style={{ zIndex: 1 }} active inverted>
            {status.request && <Loader />}
            {status.error && (
              <Message
                style={{ maxWidth: '60%', margin: '0 auto' }}
                error
                content={status.error.message}
              />
            )}
          </Dimmer>
        )}
        {children}
      </Segment>
    );
  }
}
