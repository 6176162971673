import React from 'react';
import { Statistic, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { format } from 'd3-format';
const formatNumber = format(',');

const StatisticBox = styled(Statistic)`
  &.statistic.statistic {
    background: #fff;
    border-left: 2px solid #E6E6E6;
    border-right: 2px solid #E6E6E6;
    padding: 30px;
    display: flex;
    flex: 1;
    margin: 0 !important;
    min-height: 159px;

    &:first-child {
      border-left: 4px solid #E6E6E6;
      margin-left: 1.5em !important;
    }

    &:last-child {
      border-right: 4px solid #E6E6E6;
      margin-right: 1.5em !important;
    }

    .value.value {
      font-weight: bold;
      margin-bottom: 10px;
      min-height: 70px;
    }
  }
`;

export default class NumberWidget extends React.Component {
  render() {
    const { data, field, title, status } = this.props;
    return (
      <StatisticBox>
        <Statistic.Value>
          {status.request && (
            <Loader active />
          )}
          {status.error && (
            <i title={status.error.message}>Error</i>
          )}
          {status.success && (
            <span>{formatNumber(data[field])}</span>
          )}
        </Statistic.Value>
        <Statistic.Label style={{ color: '#4A4A4A' }}>{title}</Statistic.Label>
      </StatisticBox>
    );
  }
}
