import React from 'react';
import moment from 'moment';
import { format } from 'd3-format';
import {
  colorScale,
  formatDateTickValues,
  selectYFormatter
} from 'utils/analytics';

import { Message } from 'semantic-ui-react';

import {
  VictoryChart,
  VictoryLabel,
  VictoryBar,
  VictoryLine,
  VictoryTooltip,
  VictoryAxis,
  VictoryVoronoiContainer
} from 'victory';

const formatNumber = format(',');

export default class SeriesChart extends React.Component {
  getStyles() {
    return {
      axisTime: {
        grid: {
          stroke: '#cdcdcd',
          strokeWidth: 0.5,
          strokeDasharray: '5, 5'
        },
        axis: { stroke: '#2a2a2a', strokeWidth: 1 },
        ticks: {
          size: 2,
          stroke: '#2A2A2A',
          strokeWidth: 1
        },
        tickLabels: {
          fill: '#2A2A2A',
          fontSize: 7,
          fontFamily: '"Lato", sans-serif'
        }
      },
      axisValue: {
        grid: {
          stroke: '#cdcdcd',
          strokeWidth: 0.5,
          strokeDasharray: '5, 5'
        },
        axis: { stroke: '#2a2a2a', strokeWidth: 1 },
        ticks: {
          size: 2,
          stroke: 'black',
          strokeWidth: 1
        },
        tickLabels: {
          fill: '#2A2A2A',
          fontSize: 7,
          fontFamily: '"Lato", sans-serif'
        }
      }
    };
  }
  render() {
    const {
      type,
      height,
      data = [],
      x = 'x',
      y = 'y',
      timeRange,
      markers = []
    } = this.props;

    const styles = this.getStyles();
    const defaultYFormatter = selectYFormatter(data, 'count');
    const yFormatter = this.props.yFormatter || defaultYFormatter;

    const maxY = Math.max(...data.map((o) => o[y]));

    return (
      <div>
        {maxY === 0 && (
          <Message
            size="big"
            style={{
              top: '50%',
              position: 'absolute',
              left: 0,
              right: 0,
              margin: 'auto',
              width: '350px',
              textAlign: 'center',
              background: 'rgba(241, 241, 241, 1)',
              zIndex: 1,
              boxShadow: 'none'
            }}
            content={'No data for this time range'}
          />
        )}
        <VictoryChart
          domain={timeRange && { x: [timeRange.from, timeRange.to] }}
          padding={{ left: 30, right: 5, top: 5, bottom: 25 }}
          height={height || 200}
          domainPadding={type === 'line' ? 0 : 8}
          containerComponent={<VictoryVoronoiContainer />}
        >
          {type == 'line' ? (
            <VictoryLine
              style={{
                data: { strokeWidth: 1, stroke: colorScale[1] }
              }}
              labels={(d) => [
                `Date: ${moment(d.x).format('MMM Do YYYY')}`,
                `Value: ${formatNumber(d[y])}`
              ]}
              labelComponent={
                <VictoryTooltip style={{ fontSize: 8, textAnchor: 'start' }} />
              }
              x={x}
              y={y}
              data={data}
            />
          ) : (
            <VictoryBar
              style={{
                data: { strokeWidth: 1, fill: colorScale[1] }
              }}
              labels={(d) => [
                `Date: ${moment(d.x).format('MMM Do YY')}`,
                `Value: ${d[y]}`
              ]}
              labelComponent={
                <VictoryTooltip style={{ fontSize: 6, textAnchor: 'start' }} />
              }
              x={x}
              y={y}
              data={data}
            />
          )}
          {markers.map((marker) => {
            return (
              <VictoryLine
                key={marker.text}
                style={{
                  data: {
                    strokeWidth: 1,
                    stroke: colorScale[2],
                    strokeDasharray: 2
                  },
                  labels: { angle: 90, fill: colorScale[2], fontSize: 10 }
                }}
                labels={[marker.text]}
                labelComponent={<VictoryLabel y={50} />}
                data={[
                  { x: new Date(marker.ts), y: 0 },
                  { x: new Date(marker.ts), y: maxY || 1 }
                ]}
              />
            );
          })}
          <VictoryAxis
            domain={[0, 1]}
            dependentAxis
            orientation="left"
            style={styles.axisValue}
            tickFormat={yFormatter}
          />
          {type === 'line' ? (
            <VictoryAxis
              tickCount={10}
              domain={[timeRange.from, timeRange.to]}
              scale="time"
              tickFormat={formatDateTickValues}
              style={styles.axisTime}
            />
          ) : (
            <VictoryAxis
              tickFormat={formatDateTickValues}
              style={styles.axisTime}
            />
          )}
        </VictoryChart>
      </div>
    );
  }
}
