import { observable, action } from 'mobx';
import BaseStore from 'stores/BaseStore';
import request from 'utils/request';
import Device from './item';
import appSession from 'stores/AppSession';

export default class DevicesStore extends BaseStore {
  @observable items = [];

  @action
  list(statusKey = 'list') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/devices/all',
      query: {
        organizationId: appSession.organization.id
      }
    })
      .then((resp) => {
        this.items = resp.data.map((organization) => new Device(organization));
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
