import React from 'react';
import { Segment, Dimmer, Loader, Divider, Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';

@inject('appSession', 'routing')
@observer
export default class Authenticated extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: null,
      loading: true
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (!query.token) {
      this.setState({ error: new Error('No token given'), loading: false });
      return;
    }
    const { appSession } = this.props;
    appSession.reset();
    appSession.setToken(query.token);
    this.props.routing.replace('/');
  }

  render() {
    const { error, loading } = this.state;
    return (
      <PageCenter>
        <LogoTitle />
        <Segment.Group>
          <Segment padded>
            <Divider hidden />
            {error && <Message error content={error.message} />}
            {loading && (
              <Dimmer active inverted>
                <Loader>Authenticating</Loader>
              </Dimmer>
            )}

            <Divider hidden />
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}
