import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import DateRangePicker from 'components/DateRangePicker';

@inject('filters', 'routing')
@observer
export default class Filters extends Component {
  constructor(props) {
    super(props);
    const filter = props.filters.analytics;
    this.state = {
      lookupSubject: filter.subject || ''
    };
  }

  getFilter() {
    return this.props.filters.analytics;
  }

  handleTimeRangeChange = (timeRange) => {
    this.getFilter().setTimeRange(timeRange);
  };

  render() {
    const timeRange = this.getFilter().timeRange;
    return (
      <Menu.Menu position="right">
        <Menu.Item>
          <DateRangePicker
            label="Enrolled"
            timeRange={timeRange}
            onChange={this.handleTimeRangeChange}
          />
        </Menu.Item>
      </Menu.Menu>
    );
  }
}
