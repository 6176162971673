import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Modal from 'components/Modal';
import { reaction } from 'mobx';
import StudyWizard from './StudyWizard';
import StudyKeys from './StudyKeys';
import { hasPermissions } from 'utils/rbac';

import {
  Container,
  Divider,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Dropdown,
  Button
} from 'semantic-ui-react';

@inject('studies', 'appSession', 'me')
@observer
export default class Home extends React.Component {
  state = {
    showCreateDialog: false,
    showSubjectKey: false
  };
  constructor(props) {
    super(props);
    this.wizard = React.createRef();
    this.subjectKeysDialog = React.createRef();
    const { appSession, studies } = props;

    this.disposeReaction = reaction(
      () => appSession.organization,
      () => {
        studies.list();
      }
    );
  }

  componentDidMount() {
    this.props.studies.list();
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  handleOnCreate = () => {};

  handleRemove = (organization) => {
    const { studies } = this.props;
    studies.delete(organization);
  };

  handleCreate = (body) => {
    const { studies } = this.props;
    return studies.create(body).then((result) => {
      if (result instanceof Error) return;
      const dialog = this.createDialog.current;
      if (dialog) dialog.close();
    });
  };

  handleUpdate = (body) => {
    const { studies } = this.props;
    return studies.update(body).then((err) => {
      if (err instanceof Error) return;
      const dialog = this.editDialog.current;
      if (dialog) dialog.close();
    });
  };

  render() {
    const { studies, me } = this.props;
    const deleteStatus = studies.getStatus('delete');
    const listStatus = studies.getStatus('list');

    const { editItem, showSubjectKey } = this.state;
    return (
      <Container>
        <Header as="h2" className="page-title">
          Studies
        </Header>
        <Divider hidden />
        <div className="list">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={3}>Duration</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created At</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!studies.items.length && (
                <Table.Row>
                  <Table.Cell>No studies yet</Table.Cell>
                </Table.Row>
              )}
              {studies.items.map((item) => {
                return (
                  <Table.Row key={item.data.id}>
                    <Table.Cell>
                      <Header as="h4">
                        <Header.Content>{item.data.name}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{item.data.durationDays} Days</Table.Cell>
                    <Table.Cell>
                      {moment(item.data.createdAt).format('LL')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {
                        <Dropdown
                          // disabled={!hasInviteBtn}
                          text="Actions"
                          // options={this.getActionsForMember()}
                          button
                          floating
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon="key"
                              content="Show Subject Keys"
                              onClick={() =>
                                this.setState({ showSubjectKey: item })
                              }
                            />
                            <Dropdown.Item
                              icon="edit"
                              content="Edit"
                              onClick={() => {
                                this.setState({ editItem: item }, () => {
                                  this.wizard.current.open();
                                });
                              }}
                              disabled={
                                !hasPermissions(me.user, 'studies', 'write')
                              }
                            />
                            <Modal
                              title={`Are you sure you want to delete "${item.data.name}"?`}
                              status={deleteStatus}
                              trigger={
                                <Dropdown.Item
                                  icon="delete"
                                  content="Delete"
                                  disabled={
                                    !hasPermissions(me.user, 'studies', 'write')
                                  }
                                />
                              }
                              action={{
                                content: 'Delete',
                                onClick: () => this.handleRemove(item)
                              }}
                            >
                              <p />
                              <ul>
                                <li>
                                  All underlining Task Definitions will be
                                  deleted.
                                </li>
                              </ul>
                            </Modal>
                          </Dropdown.Menu>
                        </Dropdown>
                      }
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {listStatus.request && (
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          )}
          {listStatus.error && (
            <Message error content={listStatus.error.message} />
          )}
        </div>
        <Button
          onClick={() => {
            this.setState({
              editItem: null
            });
            this.wizard.current.open();
          }}
          style={{ marginTop: '20px' }}
          primary
          content="Create New Study"
          disabled={!hasPermissions(me.user, 'studies', 'write')}
        />

        <StudyWizard
          ref={this.wizard}
          onDone={() => {
            this.setState({ editItem: null });
          }}
          initialValues={
            editItem ? editItem.data : { numSubjects: 300, durationDays: 100 }
          }
          studies={studies}
        />

        {showSubjectKey && (
          <Modal
            open
            onClose={() => this.setState({ showSubjectKey: false })}
            title={`${showSubjectKey.data.name}'s subject Keys`}
          >
            <StudyKeys study={showSubjectKey} />
          </Modal>
        )}
      </Container>
    );
  }
}
